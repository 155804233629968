import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { AuthProvider } from '@context/auth/auth-context';
import { RouterProvider } from '@context/router/router-context';
import { AppBrowserRouter } from '@routes/app-browser-router';
import { Spinner } from '@generics/spinner';
import { configuration } from '@configuration';
import { authConfiguration } from '@services/auth/auth-configuration';
import TagManager from 'react-gtm-module';
import { loadExceptionTracking } from '@services/exceptions/track-service';
import { routesConfig } from '@routes/routes-config';
import { UserProvider } from '@context/user/user-context';
import { AppProvider } from '@modules/app/app-context';
import { AppLayout } from '@modules/app/app-layout';
import '../i18n';

const SigninCallback = React.lazy(() => import('./app/signin-callback'));
const SilentRenew = React.lazy(() => import('./app/silent-renew'));
const Login = React.lazy(() => import('./app/login'));
const Logout = React.lazy(() => import('./app/logout'));
const AppPage = React.lazy(() => import('@modules/app/app-page'));

const { debug, gtm } = configuration;

if (gtm.id) TagManager.initialize({ gtmId: gtm.id });

loadExceptionTracking();

const App: React.FC = () => (
    <RouterProvider>
        <AppBrowserRouter>
            <Suspense fallback={<Spinner showFullScreen={true} />}>
                <AuthProvider authConfiguration={authConfiguration} debug={debug}>
                    <Switch>
                        <Route
                            exact
                            path={routesConfig.oidc.signinCallback}
                            component={SigninCallback}
                        />
                        <Route exact path={routesConfig.oidc.silentRenew} component={SilentRenew} />
                        <Route exact path={routesConfig.oidc.login} component={Login} />
                        <Route exact path={routesConfig.oidc.logout} component={Logout} />
                        <Route
                            render={() => (
                                <UserProvider>
                                    <AppProvider>
                                        <AppLayout>
                                            <AppPage />
                                        </AppLayout>
                                    </AppProvider>
                                </UserProvider>
                            )}
                        />
                    </Switch>
                </AuthProvider>
            </Suspense>
        </AppBrowserRouter>
    </RouterProvider>
);

export default App;
