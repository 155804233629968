import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from '@modules/app/shared/translate';
import { useTranslation } from 'react-i18next';
import { AuthUser, isCustomerArea, isIntermediary } from '@services/auth/auth-types';
import { Button } from '@generics/button';
import { CUSTOMER_HEADER_ID } from '@utils/constants';
import classNames from 'classnames';
import { DropdownMenu } from '@generics/dropdown-menu';
import { LanguageList } from '@shared/language-selector/language-list';
import { useAuthContextActions, useAuthContext } from '@context/auth/auth-context';
import { useUserContext } from '@context/user/user-context';
import { routesConfig } from '@routes/routes-config';
import { configuration } from '@configuration';
import { setUserLang } from '@utils/language-helper';
import './user-menu.scss';

const {
    oidc: { stsAuthority }
} = configuration;

const getLetterLogin = (user?: AuthUser, name?: string): string => {
    if (isCustomerArea(user))
        return name?.charAt(0).toUpperCase() || user?.email?.charAt(0).toUpperCase() || '';
    return '';
};

const UserMenu: React.FC = () => {
    const { t } = useTranslation();
    const { user } = useAuthContext();
    const { goLoginPage, goLogoutPage } = useAuthContextActions();
    const { userData } = useUserContext();

    const fullName = userData ? `${userData?.name} ${userData?.surname}` : '';
    const shortName = isIntermediary(user) ? user?.username : userData?.name;
    setUserLang('ES');

    return (
        <div className="ea-user">
            {!user ? (
                <Button
                    data-cy="login"
                    kind="tertiary"
                    value={t('private-header.login')}
                    onClick={() => goLoginPage(true, CUSTOMER_HEADER_ID)}
                    type="button"
                    wide
                />
            ) : (
                <>
                    <p className="ea-user__welcome">
                        <Translate i18nKey="private-header.hi" />
                        {shortName ? ` ${shortName}` : ''}!
                    </p>
                    <DropdownMenu
                        classes={{
                            menuClassName: 'ea-user-menu',
                            toggleClassName: 'ea-user__avatar'
                        }}
                        renderToggle={isMenuOpen => (
                            <Button
                                data-cy="user-menu-toggle-dropdown"
                                className={classNames({
                                    'ea-user__btn': true,
                                    'is-active': isMenuOpen,
                                    intermediary: isIntermediary(user)
                                })}
                                kind="secondary"
                                type="button"
                                value={getLetterLogin(user, shortName)}
                                icon={isIntermediary(user) ? 'user' : undefined}
                                size="l"
                            />
                        )}>
                        {toggleDropdown => (
                            <ul className="ea-user-menu__list">
                                <li className="ea-user-menu__item">
                                    <p className="ea-user-data__name ellipsis">{fullName}</p>
                                    <p className={'ea-user-data__email ellipsis'}>{user?.email}</p>
                                </li>
                                {isCustomerArea(user) ? (
                                    <li className="ea-user-menu__item">
                                        <Link
                                            data-cy="user-menu-my-personal-data"
                                            className="ea-user-menu__data ea-button-component effect--ripple ea-button--tertiary"
                                            to={routesConfig.customerArea.personalData}
                                            onClick={toggleDropdown}>
                                            <Translate i18nKey="private-header.my-data" />
                                        </Link>
                                    </li>
                                ) : isIntermediary(user) ? (
                                    <>
                                        <li className="ea-user-menu__item">
                                            <a
                                                className="ea-user-menu__data ea-button-component effect--ripple ea-button--tertiary"
                                                href={`${stsAuthority}/Account/ChangeEmail?returnUrl=${window.location}`}
                                                onClick={toggleDropdown}>
                                                <Translate i18nKey="private-header.change-email" />
                                            </a>
                                        </li>
                                        <li className="ea-user-menu__item">
                                            <a
                                                className="ea-user-menu__data ea-button-component effect--ripple ea-button--tertiary"
                                                href={`${stsAuthority}/Account/ChangePassword?returnUrl=${window.location}`}
                                                onClick={toggleDropdown}>
                                                <Translate i18nKey="private-header.change-password" />
                                            </a>
                                        </li>
                                    </>
                                ) : null}
                                <li className="ea-user-menu__item">
                                    <div className="ea-user-data__logout">
                                        <Button
                                            data-cy="login"
                                            kind="tertiary"
                                            value={t('private-header.logout')}
                                            onClick={goLogoutPage}
                                            type="button"
                                            wide
                                        />
                                    </div>
                                </li>
                            </ul>
                        )}
                    </DropdownMenu>
                    <div className="ea-private-header__language">
                        <LanguageList display={false} />
                    </div>
                </>
            )}
        </div>
    );
};
//<LanguageList display={isCustomerArea(user) || isIntermediary(user)} />
export { UserMenu };
