import { useEffect, useRef } from 'react';

const isTargetInsideRef = (ref: React.RefObject<HTMLElement>, eventTarget: EventTarget) => {
    return !ref.current || ref.current.contains(eventTarget as Element);
};

const useOnClickOutside = (
    handler: (e: Event) => void,
    menuRef: React.RefObject<HTMLElement>,
    menuToggleRef: React.RefObject<HTMLElement>,
    isActive: boolean
) => {
    const handlerRef = useRef<(e: Event) => void>(handler);

    useEffect(() => {
        handlerRef.current = handler;
    }, [handler]);

    useEffect(() => {
        if (isActive) {
            const listener = (event: MouseEvent | TouchEvent) => {
                if (
                    isTargetInsideRef(menuRef, event.target!) ||
                    isTargetInsideRef(menuToggleRef, event.target!)
                )
                    return;
                handlerRef.current(event);
            };

            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);
            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        }
    }, [menuRef, menuToggleRef, isActive]);
};

export { useOnClickOutside };
