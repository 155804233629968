enum CallStatus {
    NotCall,
    Loading,
    Error,
    Complete
}

export type HttpState<T> = {
    status: CallStatus;
    data?: T;
    error?: Error;
};

const REQUEST_START: string = 'REQUEST_START';
const REQUEST_ERROR: string = 'REQUEST_ERROR';
const REQUEST_END: string = 'REQUEST_END';

export type HttpAction<T> = {
    type: typeof REQUEST_START | typeof REQUEST_ERROR | typeof REQUEST_END;
    payload?: T;
    error?: Error;
};

const isInitializingOrLoading = <T>(state: HttpState<T>): boolean =>
    state.status === CallStatus.NotCall || state.status === CallStatus.Loading;

const isNotCall = <T>(state: HttpState<T>): boolean => state.status === CallStatus.NotCall;
const isLoading = <T>(state: HttpState<T>): boolean => state.status === CallStatus.Loading;
const isError = <T>(state: HttpState<T>): boolean => state.status === CallStatus.Error;
const isComplete = <T>(state: HttpState<T>): boolean => state.status === CallStatus.Complete;

export {
    isInitializingOrLoading,
    isNotCall,
    isLoading,
    isComplete,
    isError,
    CallStatus,
    REQUEST_START,
    REQUEST_END,
    REQUEST_ERROR
};
