import { GroupedOptionsType, OptionsType, GroupType } from 'react-select';

export const calcOptionsLength = <T>(options?: GroupedOptionsType<T> | OptionsType<T>): number => {
    const groupedOptionsType = options as GroupedOptionsType<T>;
    const isGrouped = groupedOptionsType?.[0]?.options !== undefined;

    if (isGrouped) {
        return groupedOptionsType.reduce(
            (result: number, group: GroupType<T>) => result + group.options.length,
            0
        );
    }
    return options?.length ?? 0;
};
