import React, { ReactNode, useRef, useState, useContext } from 'react';

export type UserConfirmationCallback = (ok: boolean) => void;

export type RouterContextProps = {
    showPrompt: boolean;
    cancelNavigation?: () => void;
    continueNavigation?: () => void;
    setUserConfirmationCallback?: (callback: UserConfirmationCallback) => void;
};

const RouterContext = React.createContext<Partial<RouterContextProps>>({}) as React.Context<
    RouterContextProps
>;

type RouterProviderProps = {
    children: ReactNode;
};

const RouterProvider: React.FC<RouterProviderProps> = ({ children }: RouterProviderProps) => {
    const callbackRef = useRef<UserConfirmationCallback>();
    const [showPrompt, setShowPrompt] = useState<boolean>(false);

    const handleNavigation = (ok: boolean): void => {
        callbackRef.current?.(ok);
        setShowPrompt(false);
    };

    const setUserConfirmationCallback = (callback: UserConfirmationCallback): void => {
        callbackRef.current = callback;
        setShowPrompt(true);
    };

    return (
        <RouterContext.Provider
            value={{
                showPrompt,
                cancelNavigation: () => handleNavigation(false),
                continueNavigation: () => handleNavigation(true),
                setUserConfirmationCallback
            }}>
            {children}
        </RouterContext.Provider>
    );
};

const RouterConsumer: React.ExoticComponent<React.ConsumerProps<RouterContextProps>> =
    RouterContext.Consumer;

const useRouterContext = () => useContext(RouterContext);

export { RouterProvider, RouterConsumer, useRouterContext };
