import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Button } from '@generics/button';
import './notification.scss';

enum NotificationKind {
    default,
    error,
    success
}

const structureClass = {
    notificationClass: 'ea-recovery-policy-notification-component'
};

type NotificationProps = {
    className?: string;
    kind?: 'default' | 'error' | 'success';
    msg: string;
    autoDismiss?: boolean;
    autoDismissMs?: number;
    onDismiss: () => void;
};

const Notification: React.FC<NotificationProps> = ({
    onDismiss,
    kind,
    msg,
    className,
    autoDismiss = false,
    autoDismissMs = 3000
}: NotificationProps) => {
    const dismissNotification = () => {
        onDismiss();
    };

    useEffect(() => {
        if (autoDismiss && autoDismissMs) {
            const timeout = setTimeout(() => {
                dismissNotification();
            }, autoDismissMs);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [autoDismiss, autoDismissMs]);

    return (
        <div
            className={classNames(
                [structureClass.notificationClass],
                kind || 'success',
                className
            )}>
            <p className="ea-recovery-policy-notification__msg">{msg}</p>
            <Button
                className="ea-recovery-policy-notification__btn"
                onClick={() => dismissNotification()}
                type="button"
                value="close"
                round
                icon="close"
                size="xs"
            />
        </div>
    );
};

export { Notification, NotificationKind };
