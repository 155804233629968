import React from 'react';
import classNames from 'classnames';

import './spinner.scss';

type SpinnerProps = {
    showFullScreen?: boolean;
};

const Spinner = ({ showFullScreen = false }: SpinnerProps) => (
    <div className={classNames('loader', { 'loader--full-screen': showFullScreen })}>
        <svg className="circular" viewBox="22 22 44 44">
            <circle
                className="path"
                cx="44"
                cy="44"
                r="20"
                fill="none"
                strokeWidth="4"
                strokeMiterlimit="10"></circle>
        </svg>
    </div>
);

export { Spinner };
