import { withBaseHttpClient } from '@services/http';
import { AxiosResponse } from 'axios';

enum LogLevel {
    Critical = 5,
    Debug = 1,
    Error = 4,
    Information = 2,
    None = 6,
    Trace = 0,
    Warning = 3
}

type LoggingEvent = {
    logLevel: LogLevel;
    message?: string;
    stack?: string;
};

const track = (event: LoggingEvent): Promise<AxiosResponse<void>> =>
    withBaseHttpClient(httpClient => httpClient.post<void>('/track', event));

const trackException = (error: Error): Promise<AxiosResponse<void>> =>
    track({
        logLevel: LogLevel.Error,
        message: error.message,
        stack: error.stack
    });

const isError = (obj: unknown): boolean => Object.prototype.toString.call(obj) === '[object Error]';

const loadExceptionTracking = () => {
    window.addEventListener('error', ev => {
        if (!isError(ev.error)) {
            const stack = `window.onerror@${location.href}:${ev.lineno}:${ev.colno || 0}`;
            const error = new Error(ev.error.message);
            error.stack = stack;

            trackException(error);
        } else {
            trackException(ev.error);
        }
    });
};

export { trackException, loadExceptionTracking };
