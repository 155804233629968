import React, { useState } from 'react';
import { IntLanguageProps } from '../language.interface';
import LanguageElement from '../language-element/language-element';
import { getImages } from '@utils/constants';
import {
    getUserLang,
    setUserLang,
    getAllowedAgencyLang,
    getLanguageDescriptive
} from '@utils/language-helper';
//import RNRestart from 'react-native-restart';

const LanguageList: React.FC<IntLanguageProps> = (props: any) => {
    const displayControl = props.display;
    const [displayLanguage, setDisplayLanguage] = useState(false);
    const [idLang, setidLang] = useState(getUserLang());
    const [, setidLanguage] = useState('');
    const dataLanguage = getAllowedAgencyLang();

    const handleSelectLanguage = (name: string, LanguageSelected: string) => {
        setidLanguage(name);
        setidLang(LanguageSelected);
        setDisplayLanguage(false);
        setUserLang(LanguageSelected);
        //RNRestart.Restart();
    };

    return (
        <div>
            {displayControl && (
                <div>
                    <div data-testid="languageList"></div>
                    <div className="language-header" onClick={() => setDisplayLanguage(true)}>
                        <img className="language-flag" src={getImages(idLang)} />
                        <span className="language-country">{getLanguageDescriptive(idLang)}</span>
                    </div>
                </div>
            )}
            <div className="list--container">
                <div className="language-element" data-testid="languageElement">
                    {dataLanguage &&
                        dataLanguage.length &&
                        displayLanguage &&
                        dataLanguage
                            .filter(
                                (language: { permite_traduccion: boolean }) =>
                                    language.permite_traduccion === true
                            )
                            .map((item: any, index: any) => (
                                <LanguageElement
                                    key={index + '-' + item.id_idioma}
                                    idLang={item.id_idioma}
                                    label={getLanguageDescriptive(item.id_idioma)}
                                    handleSelectLanguage={(Language: string) =>
                                        handleSelectLanguage(item.id_idioma, Language)
                                    }
                                />
                            ))}
                </div>
            </div>
        </div>
    );
};
export { LanguageList };
