const routesConfig = {
    oidc: {
        signinCallback: '/signin-callback',
        silentRenew: '/silent-renew',
        login: '/login',
        logout: '/logout'
    },
    funnel: {
        root: '/funnel',
        home: '/funnel/home',
        buy: '/funnel/buy',
        buyWithParams: '/funnel/buy/:saleSessionId/:productId?',
        buyProductIntermediary: '/funnel/buy-product/:productId/:entityId/:agentId?',
        buyProductIntermediaryParams:
            '/funnel/buy-product-params/:productId/:entityId/:startDate?/:finishDate?',
        comparative: '/funnel/comparative',
        data: '/funnel/data',
        payment: '/funnel/payment',
        paymentConfirm: '/funnel/payment/:paymentMethod/:saleSessionId/:status',
        paymentSuccess: '/funnel/success',
        personalization: '/funnel/personalization',
        styleguide: '/style-guide'
    },
    customerArea: {
        root: '/dashboard',
        policies: '/dashboard/policies',
        policyDetail: '/dashboard/policies/details/:policyNumber',
        personalData: '/dashboard/me'
    },
    travelnet: {
        root: '/travelnet',
        home: '/travelnet/home',
        productsWithParams: '/travelnet/products/:businessLine?',
        products: '/travelnet/products',
        policies: '/travelnet/policies',
        policyDetails: '/travelnet/policies/details/:policyNumber',
        economicData: '/travelnet/economic-data'
    },
    agencies: {
        root: '/agencies',
        landing: '/agencies/landing',
        landingWithParams: '/agencies/landing/:entityId/:agencyName?/:token?',
        home: '/agencies/home'
    }
};

export { routesConfig };
