import React, { ReactNode, memo } from 'react';
import { StepDefinition } from '@modules/app/travelnet/travelnet-launcher/travelnet-launcher-steps';

export type TravelnetLauncherStepProps = {
    children: ReactNode;
} & StepDefinition;

const TravelnetLauncherStep = memo(({ label, children }: TravelnetLauncherStepProps) => (
    <div className="ea-launcher-panel">
        <div className="ea-launcher-panel__step is-visible">
            {label && <h3 className="ea-launcher-panel__step-title">{label}</h3>}
            <div className="ea-country-selector">{children}</div>
        </div>
    </div>
));

export { TravelnetLauncherStep };
