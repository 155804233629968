const comma = ',';
const period = '.';
const minus = '-';
const minusRegExp = /-/;
const nonDigitsRegExp = /\D+/g;
const digitRegExp = /\d/;
const caretTrap = '[]';

export type CreateNumberMaskParams = {
    prefix?: string;
    suffix?: string;
    includeThousandsSeparator?: boolean;
    thousandsSeparatorSymbol?: string;
    allowDecimal?: boolean;
    decimalSymbol?: string;
    decimalLimit?: number;
    requireDecimal?: boolean;
    allowNegative?: boolean;
    allowLeadingZeroes?: boolean;
    integerLimit?: number;
};

const convertToMask = (strNumber: string): (RegExp | string)[] => {
    return strNumber.split('').map(char => (digitRegExp.test(char) ? digitRegExp : char));
};

const addThousandsSeparator = (n: string, thousandsSeparatorSymbol: string): string => {
    return n.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparatorSymbol);
};

const createNumberMask = ({
    prefix = '',
    suffix = '',
    includeThousandsSeparator = false,
    thousandsSeparatorSymbol = comma,
    allowDecimal = false,
    decimalSymbol = period,
    decimalLimit = undefined,
    requireDecimal = false,
    allowNegative = false,
    allowLeadingZeroes = false,
    integerLimit = undefined
}: CreateNumberMaskParams) => {
    const prefixLength = (prefix && prefix.length) || 0;
    const suffixLength = (suffix && suffix.length) || 0;
    const thousandsSeparatorSymbolLength =
        (thousandsSeparatorSymbol && thousandsSeparatorSymbol.length) || 0;

    const numberMask = (rawValue = ''): (RegExp | string)[] => {
        const rawValueLength = rawValue.length;

        if (rawValue === '' || (rawValue[0] === prefix[0] && rawValueLength === 1)) {
            return (prefix.split('') as (RegExp | string)[])
                .concat([digitRegExp])
                .concat(suffix.split(''));
        } else if (rawValue === decimalSymbol && allowDecimal) {
            return (prefix.split('') as (RegExp | string)[])
                .concat(['0', decimalSymbol, digitRegExp])
                .concat(suffix.split(''));
        }

        const isNegative = rawValue[0] === minus && allowNegative;
        if (isNegative) {
            rawValue = rawValue.toString().substr(1);
        }

        const indexOfLastDecimal = rawValue.lastIndexOf(decimalSymbol);
        const hasDecimal = indexOfLastDecimal !== -1;

        let integer: string;
        let fraction: (RegExp | string)[] | undefined = undefined;
        let mask: (RegExp | string)[];

        if (rawValue.slice(suffixLength * -1) === suffix) {
            rawValue = rawValue.slice(0, suffixLength * -1);
        }

        if (hasDecimal && (allowDecimal || requireDecimal)) {
            integer = rawValue.slice(
                rawValue.slice(0, prefixLength) === prefix ? prefixLength : 0,
                indexOfLastDecimal
            );

            const fractionValue = rawValue.slice(indexOfLastDecimal + 1, rawValueLength);
            fraction = convertToMask(fractionValue.replace(nonDigitsRegExp, ''));
        } else {
            if (rawValue.slice(0, prefixLength) === prefix) {
                integer = rawValue.slice(prefixLength);
            } else {
                integer = rawValue;
            }
        }

        if (integerLimit && typeof integerLimit === 'number') {
            const thousandsSeparatorRegex =
                thousandsSeparatorSymbol === '.' ? '[.]' : `${thousandsSeparatorSymbol}`;
            const numberOfThousandSeparators = (
                integer.match(new RegExp(thousandsSeparatorRegex, 'g')) || []
            ).length;

            integer = integer.slice(
                0,
                integerLimit + numberOfThousandSeparators * thousandsSeparatorSymbolLength
            );
        }

        integer = integer.replace(nonDigitsRegExp, '');

        if (!allowLeadingZeroes) {
            integer = integer.replace(/^0+(0$|[^0])/, '$1');
        }

        integer = includeThousandsSeparator
            ? addThousandsSeparator(integer, thousandsSeparatorSymbol)
            : integer;

        mask = convertToMask(integer);

        if ((hasDecimal && allowDecimal) || requireDecimal === true) {
            if (rawValue[indexOfLastDecimal - 1] !== decimalSymbol) {
                mask.push(caretTrap);
            }

            mask.push(decimalSymbol, caretTrap);

            if (fraction) {
                if (typeof decimalLimit === 'number') {
                    fraction = fraction.slice(0, decimalLimit);
                }

                mask = mask.concat(fraction);
            }

            if (requireDecimal === true && rawValue[indexOfLastDecimal - 1] === decimalSymbol) {
                mask.push(digitRegExp);
            }
        }

        if (prefixLength > 0) {
            mask = (prefix.split('') as (RegExp | string)[]).concat(mask);
        }

        if (isNegative) {
            if (mask.length === prefixLength) {
                mask.push(digitRegExp);
            }

            mask = ([minusRegExp] as (RegExp | string)[]).concat(mask);
        }

        if (suffix.length > 0) {
            mask = mask.concat(suffix.split(''));
        }

        return mask;
    };
    return numberMask;
};

export { createNumberMask };
