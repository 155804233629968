import React, { memo } from 'react';
import { Translate } from './translate';
import { configuration } from '@configuration';
import { formattedUrl } from '@utils/url-helper';
import { Icon } from '@generics/icon';

type FooterProps = {
    showPublicPortalItems: boolean;
};

const {
    socialItems,
    publicPortalItems,
    server: { CMS }
} = configuration;

const Footer = memo(({ showPublicPortalItems }: FooterProps) => {
    return (
        <footer className="ea-footer-component">
            <div className="ea-footer__container">
                <div className="grid-row stretch">
                    <aside className="grid-desktop--4 grid-tablet-portrait--12 bottom">
                        <ul className="ea-footer__social">
                            {socialItems.map((value, index) => (
                                <li key={index} className="ea-footer__social-item">
                                    <a
                                        className="ea-footer__social-link"
                                        href={value.href}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <Icon name={value.iconName} title={value.iconTitle} />
                                    </a>
                                </li>
                            ))}
                        </ul>

                        <p className="ea-footer__copyright">
                            <Translate i18nKey="footer.copyright" />
                        </p>
                    </aside>
                    {showPublicPortalItems ? (
                        <nav className="ea-footer__nav grid-desktop--8 grid-tablet-portrait--12 bottom">
                            <ul className="ea-footer__nav-list">
                                {publicPortalItems.map(
                                    (value, index) =>
                                        value.section.includes('footer') && (
                                            <li key={index} className="ea-footer__nav-item">
                                                <a
                                                    href={formattedUrl(value.href, CMS)}
                                                    className="ea-footer__nav-link"
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    <Translate i18nKey={value.titleKey} />
                                                </a>
                                            </li>
                                        )
                                )}
                            </ul>
                        </nav>
                    ) : null}
                </div>
            </div>
        </footer>
    );
});

export { Footer };
