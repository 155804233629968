import React, {
    forwardRef,
    useMemo,
    Ref,
    useCallback,
    useImperativeHandle,
    useRef,
    ComponentType
} from 'react';
import Select, { MenuListComponentProps, ValueType, components, InputProps } from 'react-select';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import { calcOptionsLength } from '@utils/select-helper';
import { Props } from 'react-select/src/Select';
import { withInputLabel, InputLabelProps } from '@generics/with-input-label';
import StateManager from 'react-select';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const HEIGHT = 56;
const WINDOW_THRESHOLD = 100;

export type SelectRef = {
    focus: () => void;
};

type SelectProps<T, R> = {
    id?: string;
    hasError?: boolean;
    disableAutoOpenMenu?: boolean;
    getOptionValue?: (option: T) => R;
    onChange?: (value: T) => void;
    orderBy?: keyof T | ((value: T) => string);
    options?: T[];
} & Omit<Props<any>, 'onChange' | 'inputId'>;

type SelectMenuListComponentProps = {
    children?: React.ReactChild[];
    maxWidth: number;
} & MenuListComponentProps<any>;

type SelectInputProps = {
    autoComplete?: string;
} & InputProps;

const MenuList = <T extends {}>({
    options,
    children,
    maxHeight,
    maxWidth,
    getValue
}: SelectMenuListComponentProps) => {
    const value = getValue() as T;

    const initialOffset = useMemo(() => options.indexOf(value) * HEIGHT, [options, value]);
    const itemCount = useMemo(() => (children as React.ReactChild[])?.length || 0, [children]);

    const height = useMemo(() => {
        const itemsHeight = itemCount * HEIGHT;
        if (maxHeight && !isNaN(maxHeight) && itemsHeight > maxHeight) {
            return maxHeight;
        } else {
            return itemsHeight;
        }
    }, [maxHeight, itemCount]);

    return (
        <List
            height={height}
            width={maxWidth}
            itemCount={itemCount}
            itemSize={HEIGHT}
            initialScrollOffset={initialOffset}>
            {({ index, style }: ListChildComponentProps) => (
                <div style={style}>{children?.[index]}</div>
            )}
        </List>
    );
};

const SelectInput = (props: SelectInputProps) => {
    const InputComponent = components.Input as ComponentType<SelectInputProps>;
    // HACK to prevent Chrome from allowing autofill
    return <InputComponent {...props} autoComplete="none" />;
};

const WindowedSelect = <T extends {}, R>(props: SelectProps<T, R>, ref: Ref<SelectRef>) => {
    const { t } = useTranslation();
    const {
        id,
        placeholder,
        orderBy,
        options,
        hasError,
        getOptionLabel,
        getOptionValue,
        isDisabled = false,
        isLoading,
        disableAutoOpenMenu = false,
        isClearable = true,
        onChange,
        ...restProps
    } = props;
    const innerRef = useRef<StateManager<T>>(null);
    const isWindowed = useMemo(() => calcOptionsLength(options) >= WINDOW_THRESHOLD, [options]);

    const orderedOptions = useMemo(() => {
        const getOrderByValue = (option: T): string | T | T[keyof T] => {
            if (typeof orderBy === 'string') {
                return option[orderBy];
            } else if (typeof orderBy === 'function') {
                return orderBy(option);
            } else {
                return option;
            }
        };

        const compareOptions = (a: T, b: T): number => {
            const aValue = getOrderByValue(a);
            const bValue = getOrderByValue(b);

            return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
        };

        return orderBy ? options?.sort(compareOptions) : options;
    }, [options, orderBy]);

    const components = useMemo(
        () => ({
            ...(isWindowed ? { MenuList: MenuList as React.FC<MenuListComponentProps<T>> } : {}),
            ...(isDisabled
                ? {
                      DropdownIndicator: null,
                      IndicatorSeparator: null
                  }
                : {}),
            ...(disableAutoOpenMenu
                ? {
                      DropdownIndicator: null
                  }
                : {}),
            Input: SelectInput
        }),
        [isWindowed, isDisabled, disableAutoOpenMenu]
    );

    const noOptionsMessage = useCallback(() => t('generics.no-options'), []);

    const placeholderValue = useMemo(() => placeholder || t('generics.select'), [placeholder]);

    const focusInput = () => {
        // HACK Select input isn't focused if we don't blur the input before
        innerRef.current?.select.blurInput();
        innerRef.current?.select.focusInput();
    };

    const onValueChange = useCallback(
        (value: ValueType<T>) => {
            focusInput();
            onChange?.(value as T);
        },
        [onChange]
    );

    useImperativeHandle(
        ref,
        () => ({
            focus: focusInput
        }),
        []
    );

    return (
        <Select
            ref={innerRef}
            {...(restProps as Props<T>)}
            options={orderedOptions}
            components={components}
            noOptionsMessage={noOptionsMessage}
            placeholder={placeholderValue}
            className={classNames('ea-selector-component', {
                'ea-validate-msg--error': hasError
            })}
            inputId={id}
            classNamePrefix="ea-selector"
            isDisabled={isDisabled}
            isLoading={isLoading}
            getOptionLabel={getOptionLabel}
            getOptionValue={(getOptionValue as unknown) as (option: T) => string}
            isClearable={isClearable && !isDisabled}
            isSearchable={!isDisabled}
            menuIsOpen={isDisabled ? false : undefined}
            openMenuOnFocus={false}
            openMenuOnClick={!disableAutoOpenMenu}
            onChange={onValueChange}
        />
    );
};

const WindowSelect = React.memo(forwardRef(WindowedSelect));

const WindowSelectInputLabel = withInputLabel(WindowSelect) as <T, R>(
    props: SelectProps<T, R> & InputLabelProps<R, SelectRef>
) => React.ReactElement<SelectProps<T, R> & InputLabelProps<R, SelectRef>>;

export { WindowSelectInputLabel as Select, WindowSelect as SelectField };
