import axios, { AxiosInstance, AxiosRequestConfig, AxiosPromise, AxiosResponse } from 'axios';
import { request, errorResponse } from './interceptors';
import { configuration } from '@configuration';
import {
    CallStatus,
    isLoading,
    isComplete,
    isError,
    isInitializingOrLoading,
    isNotCall
} from './http-types';
import { useHttp } from './use-http';
import { setupCache } from 'axios-cache-adapter';
import localforage from 'localforage';

const { serverUrl, apiUrl } = configuration.server;

const forageStore = localforage.createInstance({
    driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
    name: 'ea-request-cache'
});

const httpClientCache = setupCache({
    store: forageStore
});

let authToken: string = '';

const setAuthToken = (userToken: string): string => (authToken = userToken);

const createHttpClient = (config: AxiosRequestConfig): AxiosInstance => {
    const instance = axios.create(config);

    instance.interceptors.request.use(
        request(() => authToken),
        undefined
    );
    instance.interceptors.response.use(undefined, errorResponse);

    return instance;
};

const baseHttpClient = createHttpClient({
    headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache'
    },
    baseURL: `${serverUrl}`
});

const apiHttpClient = createHttpClient({
    headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache'
    },
    baseURL: `${serverUrl}${apiUrl}`
});

const withBaseHttpClient = <T>(
    action: (instance: AxiosInstance) => AxiosPromise<T>
): Promise<AxiosResponse<T>> => action(baseHttpClient);

const withHttpClient = <T>(
    action: (instance: AxiosInstance) => AxiosPromise<T>
): Promise<AxiosResponse<T>> => action(apiHttpClient);

export {
    httpClientCache,
    withBaseHttpClient,
    withHttpClient,
    apiHttpClient as httpClient,
    setAuthToken,
    CallStatus,
    isInitializingOrLoading,
    isNotCall,
    isLoading,
    isComplete,
    isError,
    useHttp
};
