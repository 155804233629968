import React from 'react';
import { Translate } from '@shared/translate';
import { NavLink } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';

type HeaderItemProps = {
    name: string;
    link: string;
    external?: boolean;
    isDisabled?: boolean;
    openInNewTab?: boolean;
    onClick?: () => void;
};

const HeaderItem = ({
    name,
    link,
    external,
    isDisabled,
    openInNewTab,
    onClick
}: HeaderItemProps) => {
    const { t } = useTranslation();

    const removeAccentMarks = (sentence: string) => {
        return sentence
            .normalize('NFD')
            .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, '$1$2')
            .normalize();
    };

    const onClickDataLayer = () => {
        TagManager.dataLayer({
            dataLayer: {
                category: 'Interaction',
                action: 'Menu',
                label: removeAccentMarks(t(name)),
                event: 'eventga'
            }
        });
    };

    return (
        <>
            {isDisabled ? (
                <span className="not-allowed">
                    <Translate i18nKey={name} />
                </span>
            ) : external ? (
                <a
                    className="ea-private-nav__link"
                    target={openInNewTab ? '_blank' : ''}
                    rel={openInNewTab ? 'noopener noreferrer' : ''}
                    href={link}
                    onClick={onClickDataLayer}>
                    <Translate i18nKey={name} />
                </a>
            ) : (
                <NavLink
                    className="ea-private-nav__link"
                    target={openInNewTab ? '_blank' : ''}
                    activeClassName="active"
                    to={link}
                    onClick={() => {
                        onClickDataLayer();
                        onClick;
                    }}>
                    <Translate i18nKey={name} />
                </NavLink>
            )}
        </>
    );
};

export { HeaderItem };
