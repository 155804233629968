export const getFormattedCurrency = (
    value: number | undefined,
    language = 'es',
    currency = 'EUR'
) => {
    const formattedValue = value?.toLocaleString(language, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2
    });

    if (formattedValue) {
        const parts = formattedValue.split(',');
        if (parts.length === 2) {
            const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            const decimalPart = parts[1];
            return `${integerPart},${decimalPart}`;
        }
    }

    return formattedValue;
};

export const getFormattedNumber = (value: number | undefined, language = 'es') =>
    value?.toLocaleString(language, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 20
    });

export const getDecimalSeparator = (language = 'es'): string | undefined =>
    (1.1).toLocaleString(language).replace(/\d/g, '');

export const getThousandSeparator = (language = 'es'): string | undefined =>
    (11111).toLocaleString(language).replace(/\d/g, '');

export const getFormattedNumberBySeparators = (
    value: number | undefined,
    decimalSeparator: string,
    thousandsSeparator: string = ''
): string | undefined => {
    const languageDecimalSeparator = getDecimalSeparator();
    const languageThousandsSeparator = getThousandSeparator();
    const formattedNumber = getFormattedNumber(value);
    if (formattedNumber) {
        return formattedNumber
            .replace(new RegExp(`\\${languageThousandsSeparator}`, 'g'), thousandsSeparator)
            .replace(new RegExp(`\\${languageDecimalSeparator}`, 'g'), decimalSeparator);
    }
};

export const getNumber = (
    value: string,
    language = 'es',
    decimalSeparator?: string,
    thousandsSeparator?: string
): number | undefined => {
    if (!decimalSeparator) {
        decimalSeparator = getDecimalSeparator(language);
    }
    if (!thousandsSeparator) {
        thousandsSeparator = getThousandSeparator(language);
    }
    if (
        decimalSeparator &&
        thousandsSeparator &&
        !value.endsWith(decimalSeparator) &&
        !value.endsWith(thousandsSeparator)
    ) {
        const cleanNumberValue = value
            .replace(new RegExp(`\\${thousandsSeparator}`, 'g'), '')
            .replace(new RegExp(`\\${decimalSeparator}`, 'g'), '.');
        const numberValue = parseFloat(cleanNumberValue);
        return !isNaN(numberValue) ? numberValue : undefined;
    }
};
