export default {
    oidc: {
        stsAuthority: 'https://identity-dev.europ20.es',
        clientId: 'funnel.spa',
        clientRoot: 'https://localhost:3000',
        clientScope: 'openid profile email funnel.api role'
    },
    i18n: {
        languages: ['EN', 'ES', 'EU'],
        defaultLanguage: 'ES',
        namespaces: [''],
        defaultNamespace: ''
    },
    server: {
        serverUrl: 'https://funnel-dev.europ20.es',
        //serverUrl: 'https://localhost:44379',
        apiUrl: '/api',
        CMS: 'https://cms-dev.europ20.es'
    },
    gtm: {
        id: ''
    },
    debug: true,
    socialItems: [
        {
            href: 'https://www.facebook.com/EuropAssistanceES',
            iconName: 'facebook',
            iconTitle: 'Facebook'
        },
        { href: 'https://twitter.com/EA_Spain', iconName: 'twitter', iconTitle: 'Twitter' },
        {
            href: 'https://www.youtube.com/user/europassistancespain',
            iconName: 'youtube',
            iconTitle: 'YouTube'
        },
        {
            href: 'https://www.instagram.com/ea_spain/',
            iconName: 'instagram',
            iconTitle: 'Instagram'
        },
        {
            href: 'https://www.linkedin.com/company/europ-assistance',
            iconName: 'linkedin',
            iconTitle: 'LinkedIn'
        }
    ],
    publicPortalItems: [
        { href: '/ayuda', titleKey: 'private-header.funnel.help', section: 'header' },
        { href: '/sobre-nosotros', titleKey: 'private-header.funnel.about_us', section: 'header' },
        { href: '/productos', titleKey: 'private-header.funnel.products', section: 'header' },
        { href: '/contacta', titleKey: 'routesConfig.cms.contacto', section: 'header' },
        { href: '/ayuda', titleKey: 'policy-card.help', section: 'help' },
        { href: 'https://vivequetecuido.com/', titleKey: 'footer.blog', section: 'footer' },
        { href: '/contacta', titleKey: 'footer.contact', section: 'footer' },
        { href: '/prensa', titleKey: 'footer.press', section: 'footer' },
        { href: '/informacion-legal', titleKey: 'footer.legal-info', section: 'footer' },
        { href: '/terminos-y-condiciones', titleKey: 'footer.terms', section: 'footer' }
    ],
    externalLinks: {
        travelnet: 'https://testr2.europ.es/Travelnet',
        eclaims: 'https://ea.int.eclaims.europ-assistance.com'
    }
};
