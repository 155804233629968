import React, { useState, useRef, PropsWithChildren } from 'react';
import { useOnClickOutside } from '@hooks/use-onclick-outside';
import { isFunction } from 'util';
import classNames from 'classnames';

import './dropdown-menu.scss';

type DropdownMenuStyles = {
    toggleClassName?: string;
    menuClassName?: string;
};

type DropdownMenuProps = {
    renderToggle: (isMenuOpen: boolean) => React.ReactNode;
    classes?: DropdownMenuStyles;
    isOpen?: boolean;
    children: ((toggleDropdownMenu: () => void) => React.ReactNode) | React.ReactNode;
};

const DropdownMenu = ({
    children,
    renderToggle,
    classes,
    isOpen = false
}: PropsWithChildren<DropdownMenuProps>) => {
    const [isShow, setShow] = useState(isOpen);
    const menuRef = useRef<HTMLDivElement>(null);
    const toggleMenuRef = useRef<HTMLDivElement>(null);
    const toggleDropdownMenu = () => setShow(!isShow);
    useOnClickOutside(() => setShow(false), menuRef, toggleMenuRef, isShow);

    return (
        <>
            <div
                ref={toggleMenuRef}
                onClick={toggleDropdownMenu}
                className={classNames('dropdown-menu-component', classes?.toggleClassName)}>
                {renderToggle(isShow)}
            </div>
            {isShow && (
                <div
                    className={classNames(
                        'dropdown-menu__container is-show',
                        classes?.menuClassName
                    )}
                    ref={menuRef}>
                    {isFunction(children) ? (children as Function)(toggleDropdownMenu) : children}
                </div>
            )}
        </>
    );
};

export { DropdownMenu };
