import { AxiosResponse } from 'axios';
import { withHttpClient } from './http';

export type GetBusinessLinesResponse = {
    businessLines: BusinessLine[];
};

export type BusinessLine = {
    code: string;
    description: string;
    icon: string;
    order: number;
    fixedPosition?: boolean;
    visible?: boolean;
};

export type GetProductsByBusinessLineRequest = {
    code: string;
    language: string;
};

export type GetProductsByBusinessLineResponse = {
    products: Product[];
    groups?: ProductGroup[];
};

export type Product = {
    detailsUrl: string;
    coverages: string[];
    percentDiscount: number;
    discountCode: string;
    priceFrom: number;
    content: string;
    productId: string;
    shortDescription: string;
    longDescription: string;
    businessLine: string;
    groupId?: string;
};

export type ProductGroup = {
    id: string;
    description: string;
};

const getBusinessLines = async (): Promise<AxiosResponse<GetBusinessLinesResponse>> =>
    await withHttpClient(httpClient => httpClient.get<GetBusinessLinesResponse>('businessLines'));

const getProdutsByBusinessLine = async (
    request?: GetProductsByBusinessLineRequest
): Promise<AxiosResponse<GetProductsByBusinessLineResponse>> =>
    await withHttpClient(httpClient =>
        httpClient.get<GetProductsByBusinessLineResponse>(
            `businessLines/${request?.code}/products/${request?.language}`
        )
    );

export { getBusinessLines, getProdutsByBusinessLine };
