import { useState, useEffect } from 'react';

const getScroll = () => ({
    scrollX: window?.scrollX ?? 0,
    scrollY: window?.scrollY ?? 0
});

const useWindowScroll = (onlyOnScrollEnd = true) => {
    const [windowScroll, setWindowScroll] = useState(getScroll());

    useEffect(() => {
        let scrollId: NodeJS.Timeout;

        const handleScroll = () => {
            if (onlyOnScrollEnd) {
                clearTimeout(scrollId);
                scrollId = setTimeout(() => setWindowScroll(getScroll()), 200);
            } else {
                setWindowScroll(getScroll());
            }
        };

        window.addEventListener('scroll', handleScroll, { capture: false, passive: true });
        return () => window.removeEventListener('scroll', handleScroll);
    }, [onlyOnScrollEnd]);

    return windowScroll;
};

export { useWindowScroll };
