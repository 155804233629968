import React from 'react';
import classNames from 'classnames';

type RadioProps = {
    className?: string;
    id?: string;
    value: string;
    name: string;
    label: string;
    checked: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Radio: React.FC<RadioProps> = React.memo(
    ({ className, id, value, name, checked, label, onChange }: RadioProps) => (
        <label className={classNames('ea-radio', className)}>
            <input
                className="ea-radio__input"
                type="radio"
                id={id}
                value={value}
                name={name}
                onChange={onChange}
                checked={checked}
            />
            <span className="ea-radio__value">{label}</span>
        </label>
    )
);
