import React, {
    useState,
    useRef,
    useEffect,
    ReactNode,
    Ref,
    useImperativeHandle,
    forwardRef,
    ReactNodeArray,
    memo
} from 'react';
import { useOnClickOutside } from '@hooks/use-onclick-outside';
import classNames from 'classnames';
import { Icon } from '@generics/icon';
import { TravelnetLauncherStepProps } from '@modules/app/travelnet/travelnet-launcher/travelnet-launcher-step';
import { Button } from '@generics/button';
import { useTranslation } from 'react-i18next';
import { Notification } from '@shared/notification';

export type StepDefinition = {
    title: string;
    label?: string;
    iconName: string;
    value: ReactNode;
};

type TravelnetLauncherStepsProps = {
    isValid: boolean;
    isSubmitting: boolean;
    disabled?: boolean;
    nextButtonDisabled?: boolean;
    notificationErrorMessage?: string;
    onNotificationErrorDismiss: () => void;
    onExpand?: (isExpanded: boolean) => void;
    onSubmit: () => void;
    onStepChange?: () => void;
    children: ReactNode;
};

export type TravelnetLauncherStepsRef = {
    currentStepIndex: number;
    isLastStep: boolean;
    nextStep: () => void;
    setExpanded: (expanded: boolean) => void;
};

const TravelnetLauncherSteps = (
    {
        isValid,
        isSubmitting,
        disabled = false,
        notificationErrorMessage,
        onNotificationErrorDismiss,
        onExpand,
        onStepChange,
        onSubmit,
        children
    }: TravelnetLauncherStepsProps,
    ref: Ref<TravelnetLauncherStepsRef>
) => {
    const { t } = useTranslation();
    const [isExpanded, setExpanded] = useState<boolean>(false);
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

    const maxStepIndex = useRef<number>(0);
    const toggleMenuRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(() => setExpanded(false), menuRef, toggleMenuRef, isExpanded);

    useEffect(() => {
        onExpand?.(isExpanded);
    }, [isExpanded]);

    const steps = React.Children.map(children, c => {
        const component = c as React.ReactElement<TravelnetLauncherStepProps>;
        return component.props;
    });

    const isStepEnabled = (stepIndex: number): boolean =>
        stepIndex <= currentStepIndex ||
        (stepIndex === currentStepIndex + 1 && isValid) ||
        stepIndex <= maxStepIndex.current;

    const isLastStep = Boolean(steps && currentStepIndex === steps.length - 1);

    const nextStep = () => {
        if (isLastStep) {
            onSubmit?.();
        } else {
            setCurrentStepIndex(i => i + 1);
        }
    };

    useEffect(() => {
        maxStepIndex.current = Math.max(currentStepIndex, maxStepIndex.current);
        onStepChange?.();
    }, [currentStepIndex]);

    const handleNextStep = (ev: React.MouseEvent<Element>) => {
        ev.preventDefault();
        nextStep();
    };

    useImperativeHandle(
        ref,
        () => ({
            currentStepIndex: currentStepIndex,
            isLastStep,
            nextStep,
            setExpanded: expanded => setExpanded(expanded)
        }),
        [currentStepIndex, isLastStep, nextStep]
    );

    const handleStepClick = (index: number) => () => {
        if (isStepEnabled(index)) {
            setCurrentStepIndex(index);
        }
    };

    return (
        <>
            <div className="ea-launcher__container">
                <div
                    className={classNames('ea-launcher__controls', {
                        'is-notification-active': notificationErrorMessage
                    })}
                    ref={toggleMenuRef}
                    onClick={() => setExpanded(!disabled)}>
                    {steps?.map(({ title, iconName, value }, index) => (
                        <div
                            key={title}
                            className={classNames({
                                'ea-launcher__control': true,
                                'is-enabled': isExpanded && isStepEnabled(index),
                                'is-active': isExpanded && index === currentStepIndex
                            })}
                            onClick={handleStepClick(index)}>
                            <div className="ea-launcher__control-label">
                                <span>{title}</span>
                            </div>
                            <div className="ea-launcher__info">
                                <Icon name={iconName} size="xxm" />
                                <span className="ea-launcher__info-text">{value}</span>
                            </div>
                        </div>
                    ))}
                    <div className="ea-launcher__control">
                        <Button
                            kind={isLastStep ? 'secondary' : 'primary'}
                            value={
                                isLastStep ? t('travelnet-launcher.calculate') : t('generics.next')
                            }
                            onClick={handleNextStep}
                            type="submit"
                            disabled={!isExpanded || !isValid || isSubmitting}
                        />
                    </div>
                </div>
                {notificationErrorMessage && (
                    <Notification
                        className="ea-launcher__notification"
                        kind="error"
                        msg={notificationErrorMessage}
                        onDismiss={onNotificationErrorDismiss}
                    />
                )}
            </div>
            <div ref={menuRef} className="ea-launcher-panel-wrapper">
                {isExpanded && (children as ReactNodeArray)?.[currentStepIndex]}
            </div>
        </>
    );
};

const TravelnetLauncherStepsWithRef = memo(forwardRef(TravelnetLauncherSteps));

export { TravelnetLauncherStepsWithRef as TravelnetLauncherSteps };
