import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

export enum IconSize {
    xxs,
    xs,
    s,
    m,
    xm,
    xxm,
    l,
    xl,
    xxl,
    xxxl
}

const iconSize = {
    xxs: 'size--xxs',
    xs: 'size--xs',
    s: 'size--s',
    m: 'size--m',
    xm: 'size--xm',
    xxm: 'size--xxm',
    l: 'size--l',
    xl: 'size--xl',
    xxl: 'size--xxl',
    xxxl: 'size--xxxl'
};

export type IconProps = {
    className?: string;
    name: string;
    title?: string;
    size?: 'xxs' | 'xs' | 's' | 'm' | 'xm' | 'xxm' | 'l' | 'xl' | 'xxl' | 'xxxl';
};

const Icon: FunctionComponent<IconProps> = (props: IconProps) => {
    return (
        <span
            className={classNames(
                'ea-icon-component',
                props.size ? iconSize[props.size] : null,
                props.className
            )}>
            <svg role="img">
                <title>{props.title}</title>
                <use xlinkHref={`#icon-${props.name}`}></use>
            </svg>
        </span>
    );
};

export { Icon, iconSize };
