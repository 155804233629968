import React, { useEffect, useState, useRef } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { initSaleSessionRequirements } from '@services/sale-session-service';
import { useHttp, isComplete, isInitializingOrLoading } from '@services/http';
import { ModalityTypes } from '@services/models/products-comparative-model';
import { useHistory } from 'react-router-dom';
import { routesConfig } from '@routes/routes-config';
import { Translate } from '@shared/translate';
import { getCountries } from '@services/countries-service';
import { Country } from '@services/models/countries-model';
import { Formik, FormikContextType } from 'formik';
import { CountryOption } from '@modules/app/funnel/funnel-personalization/requirement-data/country-option';
import { SelectMultiple } from '@generics/select-multiple';
import classNames from 'classnames';
import { getCancellationOptions } from '@services/cms-service';
import { TravelnetLauncherStep } from '@modules/app/travelnet/travelnet-launcher/travelnet-launcher-step';
import {
    TravelnetLauncherSteps,
    TravelnetLauncherStepsRef
} from '@modules/app/travelnet/travelnet-launcher/travelnet-launcher-steps';
import ReactTooltip from 'react-tooltip';
import { getFormattedDate, addToCurrentDate, addToDate } from '@utils/dates-helper';
import { CalendarField } from '@generics/formik/calendar-field';
import { TravellersSelector } from '@modules/app/travelnet/travelnet-launcher/travellers-selector';
import { Radio } from '@generics/radio';
import { getUserLang } from '@utils/language-helper';
import './travelnet-launcher.scss';
import TagManager from 'react-gtm-module';

type TravelnetLauncherForm = {
    countries: Country[];
    departureDate?: Date;
    arrivalDate?: Date;
    travellersOver74Years: number;
    travellersBelow74Years: number;
    launcherModality?: ModalityTypes;
};

const initialFormValues: TravelnetLauncherForm = {
    countries: [],
    travellersBelow74Years: 0,
    travellersOver74Years: 0,
    launcherModality: ModalityTypes.ASIS
};

type TravelnetLauncherProps = {
    className?: string;
    disabled?: boolean;
    onExpand?: (isExpanded: boolean) => void;
    onlyTitle?: boolean;
};

const maxTravellers = 9999;

const TravelnetLauncher = ({
    className,
    onExpand,
    disabled = false,
    onlyTitle = false
}: TravelnetLauncherProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const formRef = useRef<FormikContextType<TravelnetLauncherForm>>();
    const [cancellationOptions, fetchCancellationOptions] = useHttp(getCancellationOptions, false);
    const [saleSessionRequirements, fetchInitSaleSessionRequirements] = useHttp(
        initSaleSessionRequirements
    );
    const [countries, fetchCountries] = useHttp<Country[], string>(getCountries);
    const [idLang] = useState(getUserLang());
    const [isExpanded, setExpanded] = useState<boolean>(false);
    const [showNotificationError, setShowNotificationError] = useState<boolean>(false);
    const [cancellationOptionsDescription, setCancellationOptionsDescription] = useState<
        { [key: number]: string | undefined } | undefined
    >();
    const stepsRef = useRef<TravelnetLauncherStepsRef>(null);

    const onLauncherExpand = (expanded: boolean) => {
        setExpanded(expanded);
        onExpand?.(expanded);
    };

    const validationSchema = yup.object().shape(
        {
            countries: yup
                .array()
                .min(1)
                .required(),
            departureDate: yup
                .date()
                .nullable()
                .when('currentStep', {
                    is: () => stepsRef.current?.currentStepIndex === 1,
                    then: yup
                        .date()
                        .nullable()
                        .required()
                }),
            arrivalDate: yup
                .date()
                .nullable()
                .when('currentStep', {
                    is: () => stepsRef.current?.currentStepIndex === 2,
                    then: yup
                        .date()
                        .nullable()
                        .required()
                }),
            travellersBelow74Years: yup.number().when('travellersOver74Years', {
                is: (value: number) => value === 0 && stepsRef.current?.currentStepIndex === 3,
                then: yup.number().min(1)
            }),
            travellersOver74Years: yup.number().when('travellersBelow74Years', {
                is: (value: number) => value === 0 && stepsRef.current?.currentStepIndex === 3,
                then: yup.number().min(1)
            }),
            launcherModality: yup.number().when('currentStep', {
                is: () => stepsRef.current?.currentStepIndex === 4,
                then: yup.number().required()
            })
        },
        [['travellersOver74Years', 'travellersBelow74Years']]
    );

    useEffect(() => {
        fetchCountries(idLang);
        fetchCancellationOptions(idLang);
    }, []);

    useEffect(() => {
        if (isComplete(cancellationOptions)) {
            const descriptions: { [key: number]: string | undefined } | undefined = {
                ...cancellationOptions.data?.cancellationOptions?.reduce(
                    (value, { option, description }) => ({
                        ...value,
                        [option!]: description
                    }),
                    {}
                )
            };
            setCancellationOptionsDescription(descriptions);
        }
    }, [cancellationOptions]);

    useEffect(() => {
        if (isComplete(saleSessionRequirements) && saleSessionRequirements.data) {
            if (saleSessionRequirements.data.numProducts > 0) {
                history.push(
                    `${routesConfig.funnel.buy}/${saleSessionRequirements.data.saleSessionId}`
                );
            } else if (saleSessionRequirements.data.numProducts === 0) {
                setShowNotificationError(true);
                stepsRef.current?.setExpanded(false);
            } else {
                throw new Error('Invalid requirements numProducts response');
            }
        }
    }, [saleSessionRequirements]);

    const onSubmit = async (values: TravelnetLauncherForm): Promise<void> => {
        TagManager.dataLayer({
            dataLayer: {
                category: 'Interaccion',
                action: 'Buscador-viajes',
                label: 'Calcular',
                agrupacion_paises: values.countries.map(c => c.id).join(','),
                total_dias: Math.round(
                    Math.abs(
                        (new Date(values.arrivalDate!).getTime() +
                            8.64e7 -
                            new Date(values.departureDate!).getTime()) /
                            8.64e7
                    )
                ),
                total_pasajeros: values.travellersOver74Years + values.travellersBelow74Years,
                modalidad: values.launcherModality!,
                event: 'eventga'
            }
        });
        await fetchInitSaleSessionRequirements({
            countries: values.countries.map(c => c.id),
            departureDate: values.departureDate!,
            arrivalDate: values.arrivalDate!,
            travellersOver74Years: values.travellersOver74Years,
            travellersBelow74Years: values.travellersBelow74Years,
            launcherModality: values.launcherModality!
        });

        if (values.launcherModality) {
            sessionStorage.setItem('modalidad', values.launcherModality?.toString());
        }
    };

    const onStepChange = async (): Promise<void> => {
        const errors = await formRef.current?.validateForm();
        if (errors) {
            formRef.current?.setErrors(errors);
        }
    };

    if (onlyTitle) {
        return (
            <article
                className={classNames('ea-launcher-container', className, {
                    'is-active': isExpanded
                })}>
                <h2 className="ea-launcher-container__title">
                    <Translate i18nKey="travelnet-launcher.choose-your-insurance" />
                </h2>
            </article>
        );
    }

    return (
        <article
            className={classNames('ea-launcher-container', className, {
                'is-active': isExpanded
            })}>
            <h2 className="ea-launcher-container__title">
                <Translate i18nKey="travelnet-launcher.design-your-travel-insurance" />
            </h2>
            <div className="ea-launcher">
                <Formik
                    innerRef={(formRef as unknown) as () => void}
                    initialValues={initialFormValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}>
                    {({
                        values,
                        setFieldValue,
                        setFieldTouched,
                        isValid,
                        isSubmitting,
                        handleSubmit
                    }) => {
                        const numTravellers =
                            values.travellersBelow74Years + values.travellersOver74Years;
                        return (
                            <form>
                                <TravelnetLauncherSteps
                                    isValid={isValid}
                                    disabled={disabled}
                                    isSubmitting={isSubmitting}
                                    notificationErrorMessage={
                                        showNotificationError
                                            ? t('travelnet-launcher.no-products-message')
                                            : undefined
                                    }
                                    onNotificationErrorDismiss={() =>
                                        setShowNotificationError(false)
                                    }
                                    onExpand={onLauncherExpand}
                                    onStepChange={onStepChange}
                                    onSubmit={handleSubmit}
                                    ref={stepsRef}>
                                    <TravelnetLauncherStep
                                        label={t('travelnet-launcher.destination-section-title')}
                                        title={t('travelnet-launcher.destination-countries')}
                                        iconName="planet"
                                        value={
                                            !values.countries || values.countries.length === 0 ? (
                                                <Translate i18nKey="travelnet-launcher.destinations-empty-placeholder" />
                                            ) : values.countries.length === 1 ? (
                                                <Translate i18nKey="travelnet-launcher.destinations-one-placeholder" />
                                            ) : (
                                                <Translate
                                                    i18nKey="travelnet-launcher.destinations-many-placeholder"
                                                    options={{
                                                        numCountries: values.countries.length
                                                    }}
                                                />
                                            )
                                        }>
                                        <SelectMultiple
                                            placeholder={t(
                                                'travelnet-launcher.destination-input-placeholder'
                                            )}
                                            name="countries"
                                            options={countries.data}
                                            initialValues={values.countries}
                                            isLoading={isInitializingOrLoading(countries)}
                                            orderBy="description"
                                            getOptionLabel={value => value.description}
                                            getOptionValue={value => value.id}
                                            onChange={(value?: Country[]) => {
                                                setFieldTouched('countries');
                                                setFieldValue('countries', value);
                                            }}
                                            isOptionDisabled={value => value.ist || value.alerta}
                                            formatOptionLabel={option => (
                                                <CountryOption
                                                    option={{
                                                        description: option.description,
                                                        selectable: !option.ist && !option.alerta,
                                                        ist: option.ist ?? false,
                                                        mensaje: option.mensaje,
                                                        alerta: option.alerta ?? false
                                                    }}
                                                />
                                            )}
                                            autoFocus
                                        />
                                        <ReactTooltip
                                            id="warningOptionTooltip"
                                            place="right"
                                            type="warning"
                                            effect="solid"
                                            clickable={true}
                                            delayHide={100}
                                            html={true}
                                        />
                                        <ReactTooltip
                                            id="istDisabledOptionTooltip"
                                            place="right"
                                            type="error"
                                            effect="solid"
                                            clickable={true}
                                            delayHide={100}
                                            html={true}
                                        />
                                    </TravelnetLauncherStep>

                                    <TravelnetLauncherStep
                                        title={t('travelnet-launcher.departure-date')}
                                        iconName="calendar"
                                        value={
                                            !values.departureDate ? (
                                                <Translate i18nKey="travelnet-launcher.date-placeholder" />
                                            ) : (
                                                getFormattedDate(values.departureDate)
                                            )
                                        }>
                                        <CalendarField
                                            name="departureDate"
                                            onChange={() => {
                                                setFieldValue('arrivalDate', null);
                                                stepsRef.current?.nextStep();
                                            }}
                                            monthsShown={3}
                                            inline
                                            selectsStart
                                            startDate={values.departureDate}
                                            endDate={values.arrivalDate}
                                            minDate={addToCurrentDate({ days: 1 })}
                                            maxDate={addToCurrentDate({
                                                months: 15,
                                                days: -1
                                            })}
                                            currentDayIsMaxDate={false}
                                            autoFocus
                                        />
                                    </TravelnetLauncherStep>

                                    <TravelnetLauncherStep
                                        title={t('travelnet-launcher.arrival-date')}
                                        iconName="calendar"
                                        value={
                                            !values.arrivalDate ? (
                                                <Translate i18nKey="travelnet-launcher.date-placeholder" />
                                            ) : (
                                                getFormattedDate(values.arrivalDate)
                                            )
                                        }>
                                        <CalendarField
                                            name="arrivalDate"
                                            monthsShown={3}
                                            inline
                                            selectsEnd
                                            startDate={values.departureDate}
                                            endDate={values.arrivalDate}
                                            minDate={
                                                values.departureDate ||
                                                addToCurrentDate({ days: 1 })
                                            }
                                            maxDate={
                                                values.departureDate
                                                    ? addToDate(values.departureDate, {
                                                          years: 1,
                                                          days: -1
                                                      })
                                                    : null
                                            }
                                            currentDayIsMaxDate={false}
                                            autoFocus
                                        />
                                    </TravelnetLauncherStep>

                                    <TravelnetLauncherStep
                                        title={t('travelnet-launcher.travellers')}
                                        iconName="user"
                                        value={
                                            numTravellers === 0 ? (
                                                <Translate i18nKey="travelnet-launcher.travellers-empty-placeholder" />
                                            ) : numTravellers === 1 ? (
                                                <Translate i18nKey="travelnet-launcher.travellers-one-placeholder" />
                                            ) : (
                                                <Translate
                                                    i18nKey="travelnet-launcher.travellers-many-placeholder"
                                                    options={{ count: numTravellers }}
                                                />
                                            )
                                        }>
                                        <div className="travellers-selector__wrapper">
                                            <div className="travellers-selector__container flex--column">
                                                <h3 className="travellers-selector__title">
                                                    <Translate i18nKey="travelnet-launcher.below-74-years" />
                                                </h3>
                                                <p className="travellers-selector__description">
                                                    <Translate i18nKey="travelnet-launcher.below-74-years-placeholder" />
                                                </p>
                                            </div>
                                            <TravellersSelector
                                                value={values.travellersBelow74Years}
                                                maxValue={
                                                    maxTravellers - values.travellersOver74Years
                                                }
                                                onChange={value => {
                                                    setFieldValue(
                                                        'travellersBelow74Years',
                                                        value ?? 0,
                                                        true
                                                    );
                                                }}
                                                autoFocus
                                            />
                                        </div>
                                        <div className="travellers-selector__wrapper">
                                            <div className="travellers-selector__container flex--column">
                                                <h3 className="travellers-selector__title">
                                                    <Translate i18nKey="travelnet-launcher.over-74-years" />
                                                </h3>
                                                <p className="travellers-selector__description">
                                                    <Translate i18nKey="travelnet-launcher.over-74-years-placeholder" />
                                                </p>
                                            </div>
                                            <TravellersSelector
                                                value={values.travellersOver74Years}
                                                maxValue={
                                                    maxTravellers - values.travellersBelow74Years
                                                }
                                                onChange={value => {
                                                    setFieldValue(
                                                        'travellersOver74Years',
                                                        value ?? 0,
                                                        true
                                                    );
                                                }}
                                            />
                                        </div>
                                    </TravelnetLauncherStep>
                                    <TravelnetLauncherStep
                                        title={t('travelnet-launcher.modality')}
                                        iconName="cancellation"
                                        value={
                                            !values.launcherModality ? (
                                                <Translate i18nKey="travelnet-launcher.modality-placeholder" />
                                            ) : values.launcherModality === ModalityTypes.ASIS ? (
                                                <Translate i18nKey="travelnet-launcher.modality-asis-placeholder" />
                                            ) : values.launcherModality === ModalityTypes.ASCA ? (
                                                <Translate i18nKey="travelnet-launcher.modality-asca-placeholder" />
                                            ) : (
                                                <Translate i18nKey="travelnet-launcher.modality-canc-placeholder" />
                                            )
                                        }>
                                        <div className="ea-launcher-modality-options__nav">
                                            <div
                                                className={classNames(
                                                    'ea-launcher-modality-options__btn',
                                                    {
                                                        'is-selected':
                                                            values.launcherModality ===
                                                            ModalityTypes.ASIS
                                                    }
                                                )}>
                                                <Radio
                                                    name="launcherModality"
                                                    label={t('travelnet-launcher.modality-asis')}
                                                    value="ASIS"
                                                    checked={
                                                        values.launcherModality ===
                                                        ModalityTypes.ASIS
                                                    }
                                                    onChange={() =>
                                                        setFieldValue(
                                                            'launcherModality',
                                                            ModalityTypes.ASIS
                                                        )
                                                    }
                                                />
                                                {cancellationOptionsDescription?.[
                                                    ModalityTypes.ASIS
                                                ] && (
                                                    <div
                                                        className="ea-launcher-modality-options__caption"
                                                        dangerouslySetInnerHTML={{
                                                            __html: cancellationOptionsDescription[
                                                                ModalityTypes.ASIS
                                                            ]!
                                                        }}
                                                    />
                                                )}
                                            </div>
                                            <div
                                                className={classNames(
                                                    'ea-launcher-modality-options__btn',
                                                    {
                                                        'is-selected':
                                                            values.launcherModality ===
                                                            ModalityTypes.ASCA
                                                    }
                                                )}>
                                                <Radio
                                                    name="launcherModality"
                                                    label={t('travelnet-launcher.modality-asca')}
                                                    value="ASCA"
                                                    checked={
                                                        values.launcherModality ===
                                                        ModalityTypes.ASCA
                                                    }
                                                    onChange={() =>
                                                        setFieldValue(
                                                            'launcherModality',
                                                            ModalityTypes.ASCA
                                                        )
                                                    }
                                                />
                                                {cancellationOptionsDescription?.[
                                                    ModalityTypes.ASCA
                                                ] && (
                                                    <div
                                                        className="ea-launcher-modality-options__caption"
                                                        dangerouslySetInnerHTML={{
                                                            __html: cancellationOptionsDescription[
                                                                ModalityTypes.ASCA
                                                            ]!
                                                        }}
                                                    />
                                                )}
                                            </div>
                                            <div
                                                className={classNames(
                                                    'ea-launcher-modality-options__btn',
                                                    {
                                                        'is-selected':
                                                            values.launcherModality ===
                                                            ModalityTypes.CANC
                                                    }
                                                )}>
                                                <Radio
                                                    name="launcherModality"
                                                    label={t('travelnet-launcher.modality-canc')}
                                                    value="CANC"
                                                    checked={
                                                        values.launcherModality ===
                                                        ModalityTypes.CANC
                                                    }
                                                    onChange={() =>
                                                        setFieldValue(
                                                            'launcherModality',
                                                            ModalityTypes.CANC,
                                                            true
                                                        )
                                                    }
                                                />
                                                {cancellationOptionsDescription?.[
                                                    ModalityTypes.CANC
                                                ] && (
                                                    <div
                                                        className="ea-launcher-modality-options__caption"
                                                        dangerouslySetInnerHTML={{
                                                            __html: cancellationOptionsDescription[
                                                                ModalityTypes.CANC
                                                            ]!
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </TravelnetLauncherStep>
                                </TravelnetLauncherSteps>
                            </form>
                        );
                    }}
                </Formik>
            </div>
        </article>
    );
};

export default TravelnetLauncher;
