import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { Icon } from '@generics/icon';
import './country-option.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
type CountryOptionProps = {
    option: {
        description: string;
        selectable: boolean;
        ist: boolean;
        mensaje: string;
        alerta: boolean;
    };
};

const replaceTextWithLink = (text: string, searchText: string, url: string | undefined) => {
    if (!text) return '';
    if (text.length == 0) return '';
    const parts = text.split(new RegExp(`(${searchText})`, 'gi'));
    return parts
        .map((part, index) =>
            part.toLowerCase() === searchText.toLowerCase()
                ? `<a href="${url}" target="_blank" rel="noopener noreferrer" key=${index}>${part}</a>`
                : part
        )
        .join('');
};

const CountryOption = ({ option }: CountryOptionProps) => {
    const { t } = useTranslation();
    const text = option.mensaje?.toString();
    const searchText = t('tooltips-text.ministry-foreign-affairs');
    const url = t('tooltips-text.ministry-foreign-affairs-url');

    useEffect(() => {
        ReactTooltip.rebuild();
        return () => {
            ReactTooltip.hide();
        };
    }, []);

    return (
        <div className="requirements-country-option">
            <div className="requirements-country-option__value">{option.description}</div>
            {(!option.selectable || option.mensaje?.length > 0) && (
                <div
                    data-tip={replaceTextWithLink(text, searchText, url)}
                    data-for={
                        option.ist || option.alerta
                            ? 'istDisabledOptionTooltip'
                            : option.mensaje?.length > 0
                            ? 'warningOptionTooltip'
                            : 'disabledOptionTooltip'
                    }
                    className={classNames({
                        'requirements-country-option__icon': true,
                        'msg--error': option.ist || option.alerta,
                        'msg--warning':
                            option.ist == false &&
                            option.alerta == false &&
                            option.mensaje?.length > 0
                    })}>
                    <Icon name="exclamation" title="" size="s" />
                </div>
            )}
        </div>
    );
};

export { CountryOption };
