import { AxiosResponse } from 'axios';
import { withHttpClient } from '@services/http';
import { ModalityTypes, ProductsComparative } from '@services/models/products-comparative-model';
import { PaymentMethod, AdditionalOption, ProductDocument } from '@services/models/product-model';
import { Condition } from '@services/models/additional-conditions-model';
import { Agent } from '@services/models/agents-model';
import { BAD_REQUEST, PRECONDITION_FAILED } from 'http-status-codes';
import { HttpResponseError } from '@services/exceptions/http-response-error';
import {
    InvalidInsuredCountException,
    InsuredFilenameError,
    InsuredModifiedFileError
} from '@services/exceptions/insured';
import { Insured } from '@services/models/insured-model';
import { QuestionBase } from '@services/models/questionary-model';
import {
    ProductCode,
    ProductInfo,
    VehicleData,
    AdditionalQuestionRule,
    Address
} from '@services/models/sale-session-model';
import { GetProductGeneralConditionResponse, GetDescriptionRequest } from './product-service';

type SaleSessionBaseRequest = {
    saleSessionId?: string;
    language?: string;
};

export type InitSaleSessionRequirementsRequest = {
    countries: string[];
    departureDate: Date;
    arrivalDate: Date;
    travellersOver74Years: number;
    travellersBelow74Years: number;
    launcherModality: ModalityTypes;
    intermediary?: number;
};

export type InitSaleSessionRequirementsResponse = {
    saleSessionId: string;
    numProducts: number;
};

export type InitSaleSessionProductRequest = {
    productId: string;
    entityId?: string;
    agentId?: number;
};

export type InitSaleSessionProductResponse = {
    saleSessionId: string;
    productId: string;
};

export type SetSaleSessionProductRequest = {
    saleSessionId: string;
    productId: string;
    orderId?: number;
    toShowId?: number;
    optionId?: string;
};

export type SetDiscountRequest = {
    discountCode: string;
} & SaleSessionBaseRequest;

export type SetDiscountRateResponse = {
    discountRate: number;
    productPrice: number;
    discountAmount: number;
};
export type GetPaymentMethodsResponse = {
    paymentMethodsCollection: PaymentMethod[];
};

export type GetAdditionalProductOptionsResponse = {
    additionalOptions: AdditionalOption[];
};

export type GetAdditionalConditionsResponse = {
    conditions: Condition[];
};

export type GetAgentsResponse = {
    agents: Agent[];
};

export type ProcessInsuredFileRequest = {
    file?: File;
} & SaleSessionBaseRequest;

export type ProcessInsuredsFileResponse = {
    insuredCollection: Insured[];
};

export type PostVehiclesRequest = {
    vehicles?: VehicleData[];
} & SaleSessionBaseRequest;

export type PostInsuredsRequest = {
    insureds?: InsuredDataDto[];
} & SaleSessionBaseRequest;

export type InsuredDataDto = {
    name?: string;
    firstSurname?: string;
    secondSurname?: string;
    dateOfBirth?: Date | null;
    documentNumber?: string;
    documentType?: number | string;
    documentTypeId?: number;
};

export type AddIntermediaryDataRequest = {
    externalReferenceId?: string;
    agentId?: number;
} & SaleSessionBaseRequest;

export type PostPolicyHolderRequest = {
    companyName?: string;

    name?: string;
    firstSurname?: string;
    secondSurname?: string;
    dateOfBirth?: Date | null;

    documentType?: number;
    documentNumber?: string;
    address?: Address;
    countryDiallingCode?: number;
    phoneNumber?: string;
    email?: string;
    sms?: boolean;
    iberiaPlusCardNumber?: string;
} & SaleSessionBaseRequest;

export type AddEasyRentDataRequest = {
    address?: Address;
} & SaleSessionBaseRequest;

export type PostSubmitDataRequest = {
    acceptCommercialInfo?: boolean;
} & SaleSessionBaseRequest;

export type SetRequirementsRequest = {
    responses: QuestionBase[];
} & SaleSessionBaseRequest;

export type SetRequirementsResponse = {
    saleId?: number;
    countryGroup?: string;
    entityId?: string;
} & ProductCode;

export type DirectDebitRequest = {
    name?: string;
    surnames?: string;
    ibanCode?: string;
    authorization?: boolean;
} & SaleSessionBaseRequest;

export type DirectDebitResponse = {
    policyId: number;
};

export type PaypalPaymentRequest = {
    confirmUrl?: string;
    cancelUrl?: string;
} & SaleSessionBaseRequest;

export type PaypalPaymentResponse = {
    paymentUrl: string;
};

export type PaypalConfirmRequest = {
    token: string;
} & SaleSessionBaseRequest;

export type PaypalConfirmResponse = {
    policyId: number;
};

export type CashPaypmentResponse = {
    policyId: number;
};

export type TpvPaymentRequest = {
    confirmUrl?: string;
    cancelUrl?: string;
} & SaleSessionBaseRequest;

export type TpvPaymentResponse = {
    tpvUrl: string;
    ds_SignatureVersion: string;
    ds_MerchantParameters: string;
    ds_Signature: string;
};

export type TpvConfirmRequest = {
    dsSignature: string;
    dsMerchantParameters: string;
    dsSignatureVersion: string;
} & SaleSessionBaseRequest;

export type TpvConfirmResponse = {
    policyId: number;
};

export type GetProductInfoResponse = {
    productInfo: ProductInfo;
};

export type SendBudgetEmailRequest = {
    email?: string;
} & SaleSessionBaseRequest;

export type OnlyLanguageRequest = {
    language?: string;
};

const initSaleSessionRequirements = async (
    request?: InitSaleSessionRequirementsRequest
): Promise<AxiosResponse<InitSaleSessionRequirementsResponse>> =>
    await withHttpClient(httpClient =>
        httpClient.post<InitSaleSessionRequirementsResponse>(
            '/salesession/initialize/requirements',
            request
        )
    );

const initSaleSessionProduct = async (
    request?: InitSaleSessionProductRequest
): Promise<AxiosResponse<InitSaleSessionProductResponse>> =>
    await withHttpClient(httpClient =>
        httpClient.post<InitSaleSessionProductResponse>('/salesession/initialize/product', request)
    );

const putSaleSessionProduct = async (
    request?: SetSaleSessionProductRequest
): Promise<AxiosResponse<void>> =>
    await withHttpClient(httpClient => httpClient.post<void>('/salesession/product', request));

const getProductsComparative = async (
    request?: SaleSessionBaseRequest
): Promise<AxiosResponse<ProductsComparative>> =>
    await withHttpClient(httpClient =>
        httpClient.get<ProductsComparative>(
            `salesession/${request?.saleSessionId}/productsComparative/${request?.language}`
        )
    );

const postDiscountRate = async (
    request?: SetDiscountRequest
): Promise<AxiosResponse<SetDiscountRateResponse>> =>
    await withHttpClient(httpClient =>
        httpClient.post<SetDiscountRateResponse>('salesession/discounts', request)
    );

const deleteDiscountRate = async (request?: SaleSessionBaseRequest): Promise<AxiosResponse<void>> =>
    await withHttpClient(httpClient =>
        httpClient.delete('salesession/discounts', {
            data: request
        })
    );

const getPaymentMethods = async (
    request?: SaleSessionBaseRequest
): Promise<AxiosResponse<GetPaymentMethodsResponse>> =>
    await withHttpClient(httpClient =>
        httpClient.get<GetPaymentMethodsResponse>(
            `salesession/${request?.saleSessionId}/paymentMethods/${request?.language}`
        )
    );

const getAdditionalProductOptions = async (
    request?: SaleSessionBaseRequest
): Promise<AxiosResponse<GetAdditionalProductOptionsResponse>> =>
    await withHttpClient(httpClient =>
        httpClient.get<GetAdditionalProductOptionsResponse>(
            `salesession/${request?.saleSessionId}/additionalOptions/${request?.language}`
        )
    );

const getAdditionalConditions = (
    request?: SaleSessionBaseRequest
): Promise<AxiosResponse<GetAdditionalConditionsResponse>> =>
    withHttpClient(httpClient =>
        httpClient.get<GetAdditionalConditionsResponse>(
            `salesession/${request?.saleSessionId}/additionalConditions/${request?.language}`
        )
    );

const getAgents = (request?: SaleSessionBaseRequest): Promise<AxiosResponse<GetAgentsResponse>> =>
    withHttpClient(httpClient =>
        httpClient.get<GetAgentsResponse>(`salesession/${request?.saleSessionId}/agents`)
    );

const processInsuredFile = async (
    request?: ProcessInsuredFileRequest
): Promise<AxiosResponse<ProcessInsuredsFileResponse>> => {
    try {
        return await withHttpClient(async httpClient => {
            const bodyFormData = new FormData();
            bodyFormData.set('uploadedFile', request?.file!);
            return await httpClient.post<ProcessInsuredsFileResponse>(
                `salesession/${request?.saleSessionId}/insureds/processFile`,
                bodyFormData
            );
        });
    } catch (err) {
        if (err instanceof HttpResponseError) {
            if (err.status === BAD_REQUEST) {
                if (err.data.type === 'FileFormatException') {
                    throw new InsuredModifiedFileError();
                } else {
                    throw new InsuredFilenameError();
                }
            } else if (err.status === PRECONDITION_FAILED) {
                throw new InvalidInsuredCountException();
            }
        }
        throw err;
    }
};

const sendInsuredsFile = async (request?: ProcessInsuredFileRequest): Promise<AxiosResponse> => {
    return await withHttpClient(async httpClient => {
        const bodyFormData = new FormData();
        bodyFormData.set('uploadedFile', request?.file!);
        return await httpClient.post(
            `salesession/${request?.saleSessionId}/insureds/sendEmail`,
            bodyFormData
        );
    });
};

const postVehicles = async (request?: PostVehiclesRequest): Promise<AxiosResponse> =>
    await withHttpClient(httpClient => httpClient.post('salesession/data/vehicles', request));

const postInsureds = async (request?: PostInsuredsRequest): Promise<AxiosResponse> =>
    await withHttpClient(httpClient => httpClient.post('salesession/data/insureds', request));

const postIntermediaryData = async (request?: AddIntermediaryDataRequest): Promise<AxiosResponse> =>
    await withHttpClient(httpClient =>
        httpClient.post('salesession/data/intermediaryData', request)
    );

const postPolicyHolder = async (request?: PostPolicyHolderRequest): Promise<AxiosResponse> =>
    await withHttpClient(httpClient => httpClient.post('salesession/data/policyholder', request));

const postEasyRentData = async (request?: AddEasyRentDataRequest): Promise<AxiosResponse> =>
    await withHttpClient(httpClient => httpClient.post('salesession/data/easyRent', request));

const postDataSubmit = async (request?: PostSubmitDataRequest): Promise<AxiosResponse> =>
    await withHttpClient(httpClient =>
        httpClient.post(`salesession/${request?.saleSessionId}/data/submit`, request)
    );

const postRequirements = (
    request?: SetRequirementsRequest
): Promise<AxiosResponse<SetRequirementsResponse>> =>
    withHttpClient(httpClient =>
        httpClient.post<SetRequirementsResponse>(
            `salesession/${request?.saleSessionId}/requirements`,
            request?.responses
        )
    );

const postPaymentDirectDebit = (
    request?: DirectDebitRequest
): Promise<AxiosResponse<DirectDebitResponse>> =>
    withHttpClient(httpClient =>
        httpClient.post<DirectDebitResponse>(
            `salesession/${request?.saleSessionId}/payment/directdebit`,
            request
        )
    );

const postPaymentPaypal = (
    request?: PaypalPaymentRequest
): Promise<AxiosResponse<PaypalPaymentResponse>> =>
    withHttpClient(httpClient =>
        httpClient.post<PaypalPaymentResponse>(
            `salesession/${request?.saleSessionId}/payment/paypal`,
            request
        )
    );

const postPaymentPaypalConfirm = (
    request?: PaypalConfirmRequest
): Promise<AxiosResponse<PaypalConfirmResponse>> =>
    withHttpClient(httpClient =>
        httpClient.post<PaypalConfirmResponse>(
            `salesession/${request?.saleSessionId}/payment/paypal/confirm/${request?.language}`,
            request
        )
    );

const postPaymentCash = (request?: {
    saleSessionId?: string;
    language?: string;
}): Promise<AxiosResponse<CashPaypmentResponse>> =>
    withHttpClient(httpClient =>
        httpClient.post<CashPaypmentResponse>(
            `salesession/${request?.saleSessionId}/payment/cash/${request?.language}`,
            request
        )
    );

const postPaymentTpv = (request?: TpvPaymentRequest): Promise<AxiosResponse<TpvPaymentResponse>> =>
    withHttpClient(httpClient =>
        httpClient.post<TpvPaymentResponse>(
            `salesession/${request?.saleSessionId}/payment/tpv/${request?.language}`,
            request
        )
    );

const postPaymentTpvConfirm = (
    request?: TpvConfirmRequest
): Promise<AxiosResponse<TpvConfirmResponse>> =>
    withHttpClient(httpClient =>
        httpClient.post<TpvConfirmResponse>(
            `salesession/${request?.saleSessionId}/payment/tpv/confirm/${request?.language}`,
            request
        )
    );

const getProductDocuments = (
    request?: SaleSessionBaseRequest
): Promise<AxiosResponse<ProductDocument[]>> =>
    withHttpClient(httpClient =>
        httpClient.get<ProductDocument[]>(
            `salesession/${request?.saleSessionId}/product/documents/${request?.language}`
        )
    );

const getProductInfo = (
    request?: SaleSessionBaseRequest
): Promise<AxiosResponse<GetProductInfoResponse>> =>
    withHttpClient(httpClient =>
        httpClient.get<GetProductInfoResponse>(
            `salesession/${request?.saleSessionId}/product/info/${request?.language}`
        )
    );

const getWellcomePackBundle = (request?: SaleSessionBaseRequest): Promise<AxiosResponse<Blob>> =>
    withHttpClient(httpClient =>
        httpClient.get<Blob>(
            `salesession/${request?.saleSessionId}/documents/wellcomepack/${request?.language}`,
            {
                responseType: 'blob'
            }
        )
    );

const getDocumentsBundle = (request?: SaleSessionBaseRequest): Promise<AxiosResponse<Blob>> =>
    withHttpClient(httpClient =>
        httpClient.get<Blob>(
            `salesession/${request?.saleSessionId}/product/documents/bundle/${request?.language}`,
            {
                responseType: 'blob'
            }
        )
    );

const getDocumentCaratula = (request?: SaleSessionBaseRequest): Promise<AxiosResponse<Blob>> =>
    withHttpClient(httpClient =>
        httpClient.get<Blob>(
            `salesession/${request?.saleSessionId}/product/documents/caratula/${request?.language}`,
            {
                responseType: 'blob'
            }
        )
    );

const getGeneralCondition = (
    request?: GetDescriptionRequest
): Promise<AxiosResponse<GetProductGeneralConditionResponse>> =>
    withHttpClient(httpClient =>
        httpClient.get<GetProductGeneralConditionResponse>(
            `products/${request?.productId}/generalCondition`
        )
    );

const addPolicies = (request?: SaleSessionBaseRequest): Promise<AxiosResponse<void>> =>
    withHttpClient(httpClient =>
        httpClient.post<void>(`salesession/${request?.saleSessionId}/addPolicies`)
    );

const addCustomerData = (request?: SaleSessionBaseRequest): Promise<AxiosResponse<void>> =>
    withHttpClient(httpClient =>
        httpClient.post<void>(`salesession/${request?.saleSessionId}/addCustomerInfo`)
    );

const getQuestionaryAdditionalRules = async (
    request?: SaleSessionBaseRequest
): Promise<AxiosResponse<AdditionalQuestionRule[]>> =>
    await withHttpClient(httpClient =>
        httpClient.get<AdditionalQuestionRule[]>(
            `salesession/${request?.saleSessionId}/questionary/additionalRules`
        )
    );

const postSendBudgetEmail = (request?: SendBudgetEmailRequest): Promise<AxiosResponse> =>
    withHttpClient(httpClient =>
        httpClient.post(
            `salesession/${request?.saleSessionId}/documents/budget/sendEmail/${request?.language}`,
            request
        )
    );

const getRecurrentTextPayment = async (request?: OnlyLanguageRequest): Promise<AxiosResponse> =>
    withHttpClient(httpClient =>
        httpClient.get(`salesession/recurrentTextPayment/${request?.language}`)
    );

export {
    initSaleSessionProduct,
    initSaleSessionRequirements,
    getProductsComparative,
    putSaleSessionProduct,
    postDiscountRate,
    deleteDiscountRate,
    getPaymentMethods,
    getAdditionalProductOptions,
    getAdditionalConditions,
    getAgents,
    processInsuredFile,
    postIntermediaryData,
    postPolicyHolder,
    postEasyRentData,
    postInsureds,
    postVehicles,
    postDataSubmit,
    postRequirements,
    postPaymentDirectDebit,
    postPaymentPaypal,
    postPaymentPaypalConfirm,
    postPaymentCash,
    sendInsuredsFile,
    postPaymentTpv,
    postPaymentTpvConfirm,
    getProductDocuments,
    getProductInfo,
    getWellcomePackBundle,
    getDocumentsBundle,
    getDocumentCaratula,
    getGeneralCondition,
    addPolicies,
    addCustomerData,
    getQuestionaryAdditionalRules,
    postSendBudgetEmail,
    getRecurrentTextPayment
};
