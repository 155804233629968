import React, { useState, useRef, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Translate } from '@modules/app/shared/translate';
import { ReactComponent as LogoEA } from '@assets/images/logo.svg';
import { useWindowScroll } from '@hooks/use-scroll';
import { AuthUser, isIntermediary, isCustomerArea } from '@services/auth/auth-types';
import { UserMenu } from './private-header/user-menu';
import { Icon } from '@generics/icon';
import { routesConfig } from '@routes/routes-config';
import { configuration } from '@configuration';
import classNames from 'classnames';
import { useOnClickOutside } from '@hooks/use-onclick-outside';
import { useAuthContext } from '@context/auth/auth-context';
import TravelnetLauncher from '@modules/app/travelnet/travelnet-launcher';
import { getExternalUrl } from '@utils/url-helper';
import { HeaderItem } from '@shared/private-header/header-item';
import { useUserContext } from '@context/user/user-context';
import { useAppContextActions } from '@modules/app/app-context';
import { Link, useLocation } from 'react-router-dom';
import { LanguageList } from '@shared/language-selector/language-list';

//http://localhost:3000/agencies/landing/0013192 -> EN
//http://localhost:3000/agencies/landing/0018137 -> ES
//http://localhost:3000/agencies/landing/0012490 -> ES EN

export type HeaderType = 'funnel' | 'travelnet' | 'customerarea';

const getHeaderType = (user?: AuthUser): HeaderType => {
    if (isIntermediary(user)) {
        return 'travelnet';
    } else if (isCustomerArea(user)) {
        return 'customerarea';
    } else {
        return 'funnel';
    }
};

const linkConfig: {
    [id: string]: {
        name: string;
        link: string;
        requireHasData?: boolean;
        requireBusinessLines?: boolean;
        external?: boolean;
        className?: string;
    }[];
} = {
    funnel: [
        {
            name: 'private-header.funnel.products',
            link: getExternalUrl('products'),
            external: true
        },
        {
            name: 'private-header.funnel.about_us',
            link: getExternalUrl('about_us'),
            external: true
        },
        {
            name: 'private-header.funnel.help',
            link: getExternalUrl('help'),
            external: true
        },
        {
            name: 'private-header.funnel.eclaims',
            link: configuration.externalLinks.eclaims,
            external: true,
            className: 'nav-item-light'
        }
    ],
    travelnet: [
        { name: 'private-header.travelnet.my-policies', link: routesConfig.travelnet.policies },
        {
            name: 'private-header.travelnet.products',
            link: routesConfig.travelnet.products,
            requireBusinessLines: true
        },
        {
            name: 'private-header.travelnet.economic-data',
            link: routesConfig.travelnet.economicData
        },
        {
            name: 'private-header.travelnet.agents',
            link: configuration.externalLinks.travelnet,
            external: true
        },
        {
            name: 'private-header.travelnet.contact',
            link: getExternalUrl('contact'),
            external: true
        },
        {
            name: 'private-header.funnel.eclaims',
            link: configuration.externalLinks.eclaims,
            external: true,
            className: 'nav-item-light'
        }
    ],
    customerarea: [
        {
            name: 'private-header.my-policies',
            link: routesConfig.customerArea.policies,
            requireHasData: true
        },
        {
            name: 'private-header.funnel.help',
            link: getExternalUrl('help'),
            external: true
        },
        {
            name: 'private-header.funnel.eclaims',
            link: configuration.externalLinks.eclaims,
            external: true,
            className: 'nav-item-light'
        }
    ]
};

type HeaderPrivateZoneProps = {
    showTravelnetLauncher?: boolean;
    saleInProgress?: boolean;
    secondaryIconPath?: string;
    showUserMenu?: boolean;
    showLinks?: boolean;
};

const HeaderPrivateZone = memo(
    ({
        showTravelnetLauncher = false,
        saleInProgress = false,
        secondaryIconPath,
        showUserMenu = true,
        showLinks = true
    }: HeaderPrivateZoneProps) => {
        const { t } = useTranslation();
        const [isMobileMenuShow, setMobileMenuShow] = useState(false);
        const [isLauncherExpanded, setLauncherExpanded] = useState(false);
        const menuRef = useRef<HTMLUListElement>(null);
        const toggleMenuRef = useRef<HTMLButtonElement>(null);
        const { scrollY } = useWindowScroll(false);
        const { user } = useAuthContext();
        const { hasUserData, hasBusinessLines } = useUserContext();
        const { getHomeUrl } = useAppContextActions();
        const location = useLocation();

        const scrollAnimation = scrollY >= 10 && 'header--min';
        const headerType = getHeaderType(user);

        useOnClickOutside(() => setMobileMenuShow(false), menuRef, toggleMenuRef, isMobileMenuShow);

        const onExpandLauncher = (isExpanded: boolean): void => {
            setLauncherExpanded(isExpanded);
        };

        const homeUrl = getHomeUrl();

        const objectElement = useMemo(
            () => React.createElement('object', { data: secondaryIconPath }),
            [secondaryIconPath]
        );

        return (
            <>
                <header
                    className={classNames('ea-private-header-component', scrollAnimation, {
                        'is-launcher-expanded': isLauncherExpanded,
                        'home-header': showTravelnetLauncher
                    })}
                    role="banner">
                    <div className="ea-private-header__container">
                        <div className="ea-private-header__wrapper">
                            {showLinks ? (
                                <button
                                    onClick={() => setMobileMenuShow(!isMobileMenuShow)}
                                    className={classNames(
                                        'ea-private-header__btn-menu effect--ripple',
                                        {
                                            'is-active': isMobileMenuShow
                                        }
                                    )}
                                    ref={toggleMenuRef}>
                                    <Icon
                                        name={isMobileMenuShow ? 'close' : 'menu'}
                                        title={t('generics.menu')}
                                        size="m"
                                    />
                                </button>
                            ) : null}
                            <div className="ea-private-header__logo">
                                {homeUrl.isExternal ? (
                                    <a
                                        target={saleInProgress ? '_blank' : undefined}
                                        href={homeUrl.url}>
                                        <LogoEA />
                                    </a>
                                ) : (
                                    <Link
                                        target={saleInProgress ? '_blank' : undefined}
                                        to={homeUrl.url}>
                                        <LogoEA />
                                    </Link>
                                )}
                                {secondaryIconPath ? (
                                    <div className="ea-private-header__secondary-logo">
                                        {objectElement}
                                    </div>
                                ) : null}
                            </div>
                            <p className="ea-private-header__title">
                                <Translate i18nKey="private-header.welcome" />
                            </p>
                            {showUserMenu ? <UserMenu /> : null}
                            <div className="ea-private-header__language">
                                <LanguageList
                                    display={routesConfig.agencies.home === location.pathname}
                                />
                            </div>
                        </div>
                    </div>
                    <nav
                        className={classNames('ea-private-nav', { 'is-open': isMobileMenuShow })}
                        role="navigation">
                        {showLinks ? (
                            <ul className="ea-private-nav__list" ref={menuRef}>
                                {linkConfig[headerType].map(link =>
                                    !link.requireBusinessLines || hasBusinessLines ? (
                                        <li
                                            className={classNames(
                                                'ea-private-nav__item',
                                                link.className
                                            )}
                                            key={link.name}>
                                            <HeaderItem
                                                {...link}
                                                openInNewTab={saleInProgress || link.external}
                                                isDisabled={link.requireHasData && !hasUserData}
                                                onClick={() => setMobileMenuShow(false)}
                                            />
                                        </li>
                                    ) : null
                                )}
                            </ul>
                        ) : null}
                    </nav>
                </header>
                {showTravelnetLauncher && (
                    <TravelnetLauncher disabled={!hasBusinessLines} onExpand={onExpandLauncher} />
                )}
            </>
        );
    }
);

export { HeaderPrivateZone };
