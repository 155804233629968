import { AxiosResponse } from 'axios';
import React, { useReducer, useCallback, useEffect } from 'react';
import { HttpReducer } from './http-reducer';
import {
    CallStatus,
    HttpAction,
    HttpState,
    REQUEST_END,
    REQUEST_ERROR,
    REQUEST_START
} from './http-types';
import { HttpResponseError } from '@services/exceptions';

const request = async <T, P = {}, R = AxiosResponse<T>>(
    callback: (requestData?: P) => Promise<AxiosResponse<T>>,
    dispatch: React.Dispatch<HttpAction<R>>,
    requestData?: P,
    transformResponse?: (response: AxiosResponse<T>) => R
): Promise<CallStatus> => {
    try {
        dispatch({ type: REQUEST_START });
        const response = await callback(requestData);
        dispatch({ type: REQUEST_END, payload: (transformResponse?.(response) ?? response) as R });
        return CallStatus.Complete;
    } catch (err) {
        dispatch({ type: REQUEST_ERROR, error: err });
        return CallStatus.Error;
    }
};

const useHttp = <T, P = {}>(
    callback: (requestData?: P) => Promise<AxiosResponse<T>>,
    throwExceptionOnError: boolean = true
): [HttpState<T>, (requestData?: P) => Promise<CallStatus>] => {
    const [state, dispatch] = useReducer<React.Reducer<HttpState<T>, HttpAction<T>>>(HttpReducer, {
        status: CallStatus.NotCall
    });

    const action = useCallback(
        (data?: P) => request(callback, dispatch, data, response => response.data),
        [callback]
    );

    useEffect(() => {
        if (throwExceptionOnError && state?.error instanceof HttpResponseError) {
            throw state.error;
        }
    }, [state.error, throwExceptionOnError]);

    return [state, action];
};

const useAxios = <T, P = {}>(
    callback: (requestData?: P) => Promise<AxiosResponse<T>>
): [HttpState<AxiosResponse<T>>, (requestData?: P) => Promise<CallStatus>] => {
    const [state, dispatch] = useReducer<
        React.Reducer<HttpState<AxiosResponse<T>>, HttpAction<AxiosResponse<T>>>
    >(HttpReducer, { status: CallStatus.NotCall });

    const action = useCallback((data?: P) => request(callback, dispatch, data), [callback]);

    return [state, action];
};

export { useHttp, useAxios };
