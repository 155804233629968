import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { configuration } from '@configuration';

const {
    debug,
    i18n: { languages, defaultLanguage, namespaces, defaultNamespace }
} = configuration;

i18n.use(LanguageDetector)
    .use(HttpApi)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: (language: string) =>
                `/static/locales/${language.toString().toLowerCase()}.json`,
            allowMultiLoading: false,
            queryStringParams: {}
        },
        detection: {
            htmlTag: document.documentElement
        },
        whitelist: languages,
        //lng: defaultLanguage,
        fallbackLng: defaultLanguage,
        ns: namespaces,
        defaultNS: defaultNamespace,
        debug,
        interpolation: {
            escapeValue: false, // react already safes from xss
            formatSeparator: ','
        },
        react: {
            wait: true
        }
    });

export default i18n;
