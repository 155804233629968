import React, { useMemo, useState, useEffect, ChangeEvent, useCallback } from 'react';
import MaskedInput from 'react-text-mask';
import { createNumberMask, CreateNumberMaskParams } from '@utils/number-mask';
import { useTranslation } from 'react-i18next';
import {
    getNumber,
    getFormattedNumberBySeparators,
    getDecimalSeparator,
    getThousandSeparator
} from '@utils/number-helper';
import classNames from 'classnames';

type Config = {
    allowDecimal?: boolean;
    decimalLimit?: number;
    allowNegative?: boolean;
    includeThousandsSeparator?: boolean;
    decimalSymbol?: string;
    integerLimit?: number;
};

type Type = 'number' | 'integer' | 'amount';

const typeConfig: { [type: string]: Config } = {
    number: {
        allowDecimal: true,
        includeThousandsSeparator: false,
        allowNegative: true
    },
    integer: { allowDecimal: false, includeThousandsSeparator: false },
    amount: { allowDecimal: true, decimalLimit: 2, includeThousandsSeparator: true }
};

export type NumberInputProps = {
    value?: number | null;
    hasError?: boolean;
    type?: Type;
    config?: Config;
    onChange?: (ev: ChangeEvent<HTMLInputElement>, value: number | null) => void;
} & Omit<JSX.IntrinsicElements['input'], 'onChange' | 'value'>;

const NumberInput = ({
    hasError,
    type = 'number',
    config,
    value,
    onBlur,
    onChange,
    ...restProps
}: NumberInputProps) => {
    const {
        i18n: { language }
    } = useTranslation();
    const [internalValue, setInternalValue] = useState<string>();

    const maskConfig: CreateNumberMaskParams = useMemo(
        () => ({
            ...typeConfig[type],
            decimalSymbol: getDecimalSeparator(language),
            thousandsSeparatorSymbol: getThousandSeparator(language),
            prefix: '',
            suffix: '',
            ...config
        }),
        [language, type, config]
    );

    useEffect(() => {
        setInternalValue(
            getFormattedNumberBySeparators(
                value as number,
                maskConfig.decimalSymbol!,
                maskConfig.includeThousandsSeparator ? maskConfig.thousandsSeparatorSymbol : ''
            )
        );
    }, [value, maskConfig]);

    const numberMask = useMemo(() => createNumberMask(maskConfig), [maskConfig]);

    const inputClassNames = classNames(
        {
            'ea-textfield': true,
            'ea-validate-msg--error': hasError
        },
        restProps.className
    );

    const getNumericValue = (stringValue: string): number | null => {
        const numericValue = getNumber(
            stringValue,
            language,
            maskConfig.decimalSymbol,
            maskConfig.thousandsSeparatorSymbol
        );
        return numericValue ?? null;
    };

    const handleNumericValueChange = useCallback(
        (ev: ChangeEvent<HTMLInputElement>) => {
            const previousValue = internalValue;
            const stringValue = ev.target.value;
            setInternalValue(stringValue);
            const numericValue = getNumericValue(stringValue);
            if ((!stringValue || numericValue !== undefined) && value !== numericValue) {
                onChange?.(ev, numericValue ?? null);

                if (ev.isDefaultPrevented()) {
                    setInternalValue(previousValue);
                    ev.target.value = previousValue!;
                }
            }
        },
        [internalValue, onChange]
    );

    const handleBlur = useCallback(
        (ev: React.FocusEvent<HTMLInputElement>) => {
            handleNumericValueChange(ev);
            onBlur?.(ev);
        },
        [onBlur, handleNumericValueChange]
    );

    const handleChange = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => {
            handleNumericValueChange(ev);
        },
        [handleNumericValueChange]
    );

    return (
        <MaskedInput
            {...(restProps as object)}
            value={internalValue}
            mask={numberMask}
            className={inputClassNames}
            type="text"
            onBlur={handleBlur}
            onChange={handleChange}
        />
    );
};

export { NumberInput };
