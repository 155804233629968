import { LANGUAGE } from './constants';
import i18next, { TFunction } from 'i18next';
import { AllowedLanguages } from '@services/models/language-model';

export const isSpanishLang = (lang: string) => {
    return lang === LANGUAGE.ES;
};

export const isBasqueLang = (lang: string) => {
    return lang === LANGUAGE.EU;
};

export const getLanguage = () => i18next.language || window.localStorage.i18nextLng;

export const withTranslate = <T>(t: TFunction, fieldName: keyof T, data: T[]): T[] =>
    data.map((row: T) => ({
        ...row,
        [fieldName]: t((row[fieldName] as unknown) as string)
    }));

export const getUserLang = () => {
    const lang = localStorage.getItem('i18nextLng');
    return lang ? lang : 'ES';
};

export const setUserLang = (lang: string): void => {
    if (localStorage.getItem('i18nextLng') === lang) return;
    localStorage.setItem('i18nextLng', lang);
    i18next.changeLanguage(lang);
    window.location.reload();
};

export const setAllowedAgencyLang = (lang: AllowedLanguages[]): void => {
    if (lang != []) {
        const json = JSON.stringify(lang);
        localStorage.setItem('AllowedLanguages', json);
    }
};

export const getAllowedAgencyLang = () => {
    const json = localStorage.getItem('AllowedLanguages');
    if (json == null) {
        return [
            { id_idioma: LANGUAGE.ES, permite_traduccion: true },
            { id_idioma: LANGUAGE.EN, permite_traduccion: true },
            { id_idioma: LANGUAGE.EU, permite_traduccion: true }
        ];
    }
    return JSON.parse(json);
};
export const getLanguageDescriptive = (value: string) => {
    if (value == LANGUAGE.ES) return 'Español';
    if (value == LANGUAGE.EN) return 'English';
    if (value == LANGUAGE.EU) return 'Euskara';
    return 'Español';
};
