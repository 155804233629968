import React, { ReactChild } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useRouterContext } from '@context/router/router-context';

type CustomBrowserRouterProps = {
    children: ReactChild;
};

const AppBrowserRouter = ({ children }: CustomBrowserRouterProps) => {
    const { setUserConfirmationCallback } = useRouterContext();
    return (
        <BrowserRouter
            getUserConfirmation={(_, callback) => setUserConfirmationCallback?.(callback)}>
            {children}
        </BrowserRouter>
    );
};

export { AppBrowserRouter };
