import React, { useCallback } from 'react';
import { CalendarProps, Calendar } from '@generics/calendar';
import { FieldProps, FieldInputProps, FormikProps, Field } from 'formik';
import { withInputLabel } from '@generics/with-input-label';

type CalendarFieldProps = { name: string } & Omit<CalendarProps, 'selected'>;

const CalendarField = ({ name, onChange, onBlur, ...restProps }: CalendarFieldProps) => {
    const handleChange = useCallback(
        (form: FormikProps<Date>) => (value: Date | null) => {
            form.setFieldTouched(name, true);
            form.setFieldValue(name, value, true);
            onChange?.(value);
        },
        [onChange, name]
    );

    const handleBlur = useCallback(
        (field: FieldInputProps<string | undefined>) => (
            ev: React.FocusEvent<HTMLInputElement>
        ) => {
            field.onBlur(ev);
            onBlur?.(ev);
        },
        [onBlur]
    );

    return (
        <Field name={name}>
            {({ field, form }: FieldProps<string | undefined>) => {
                const { value, ...restField } = field;
                return (
                    <Calendar
                        {...restField}
                        {...restProps}
                        selected={value ? new Date(value) : undefined}
                        onChange={handleChange(form)}
                        onBlur={handleBlur(field)}
                    />
                );
            }}
        </Field>
    );
};

const CalendarFieldWithLabel = withInputLabel<Date, CalendarFieldProps>(CalendarField, {
    name: 'calendar',
    size: 's'
});

export { CalendarFieldWithLabel, CalendarField };
