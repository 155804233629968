import { AxiosResponse } from 'axios';
import { withHttpClient } from './http';
import { HttpResponseError } from './exceptions';
import {
    Customer,
    CustomerKeyValue,
    CountryDiallingCode,
    CustomerAddress
} from '@services/models/customer-model';
import { PolicySummary } from '@services/models/policies-model';
import { NOT_FOUND, CONFLICT } from 'http-status-codes';
import {
    PolicyNotFoundError,
    PolicyUserExistsError,
    PolicyIntermediaryError,
    UserAlreadyExistsWithDniError
} from '@services/exceptions/customer';
import { HTTP_CACHE_MASTER_DATA } from '@utils/constants';

export type AddPolicyRequest = {
    numPolicy?: number;
    numDocument: string;
    amount?: number;
};

const addPolicy = async (
    addPolicyRequest?: AddPolicyRequest
): Promise<AxiosResponse<PolicySummary>> => {
    try {
        return await withHttpClient(httpClient =>
            httpClient.post('/customers/policies', addPolicyRequest)
        );
    } catch (err) {
        if (err instanceof HttpResponseError) {
            if (err.status === NOT_FOUND) {
                throw new PolicyNotFoundError();
            } else if (err.status === CONFLICT) {
                if (err.data.type === 'policynotfound') {
                    throw new PolicyUserExistsError();
                } else if (err.data.type === 'intermediarypolicyerror') {
                    throw new PolicyIntermediaryError();
                } else {
                    throw err;
                }
            }
        }
        throw err;
    }
};

const removePolicy = (policyNumber?: number): Promise<AxiosResponse<void>> =>
    withHttpClient(httpClient => httpClient.delete(`/customers/policies/${policyNumber}`));

const getCustomerData = (): Promise<AxiosResponse<Customer>> =>
    withHttpClient(httpClient => httpClient.get<Customer>('/customers'));

const getHasCustomerData = (): Promise<AxiosResponse<boolean>> =>
    withHttpClient(httpClient => httpClient.get<boolean>('/customers/hasdata'));

const updateCustomer = async (customer?: Customer): Promise<AxiosResponse<Customer>> => {
    try {
        return await withHttpClient(httpClient => httpClient.put<Customer>('/customers', customer));
    } catch (err) {
        if (err instanceof HttpResponseError) {
            if (err.status === CONFLICT) {
                throw new UserAlreadyExistsWithDniError();
            }
        }
        throw err;
    }
};

const createCustomer = async (customer?: Customer): Promise<AxiosResponse<Customer>> => {
    try {
        return await withHttpClient(httpClient =>
            httpClient.post<Customer>('/customers', customer)
        );
    } catch (err) {
        if (err instanceof HttpResponseError) {
            if (err.status === CONFLICT) {
                throw new UserAlreadyExistsWithDniError();
            }
        }
        throw err;
    }
};

const getDocumentTypes = (): Promise<AxiosResponse<CustomerKeyValue[]>> =>
    withHttpClient(httpClient =>
        httpClient.get<CustomerKeyValue[]>('/customers/documentTypes', {
            cache: HTTP_CACHE_MASTER_DATA
        })
    );

const getFormAdresses = (): Promise<AxiosResponse<CustomerKeyValue[]>> =>
    withHttpClient(httpClient =>
        httpClient.get<CustomerKeyValue[]>('/customers/formAddresses', {
            cache: HTTP_CACHE_MASTER_DATA
        })
    );

const getRoadTypes = (): Promise<AxiosResponse<CustomerKeyValue[]>> =>
    withHttpClient(httpClient =>
        httpClient.get<CustomerKeyValue[]>('/customers/roadTypes', {
            cache: HTTP_CACHE_MASTER_DATA
        })
    );

const getCountryDiallingCodes = (
    language?: string
): Promise<AxiosResponse<CountryDiallingCode[]>> =>
    withHttpClient(httpClient =>
        httpClient.get<CountryDiallingCode[]>(`/customers/countryDiallingCodes/${language}`, {
            cache: HTTP_CACHE_MASTER_DATA
        })
    );

const updateCustomerAddress = (
    address?: CustomerAddress
): Promise<AxiosResponse<CustomerAddress>> =>
    withHttpClient(httpClient => httpClient.post<CustomerAddress>('/customers/address', address));

export {
    getCustomerData,
    getHasCustomerData,
    updateCustomer,
    createCustomer,
    getDocumentTypes,
    getFormAdresses,
    getRoadTypes,
    getCountryDiallingCodes,
    updateCustomerAddress,
    addPolicy,
    removePolicy
};
