import { AxiosResponse } from 'axios';
import { withHttpClient } from '@services/http';
import { CancellationOption } from '@services/models/cms-model';

type GetCancellationOptionsResponse = {
    cancellationOptions: CancellationOption[];
};

//https://https://cms-dev.europ20.es/api/europasistance-sf/cancellations
const getCancellationOptions = (
    language?: string
): Promise<AxiosResponse<GetCancellationOptionsResponse>> =>
    withHttpClient(httpClient =>
        httpClient.get<GetCancellationOptionsResponse>(`/cms/cancellationOptions/${language}`)
    );

export { getCancellationOptions };
