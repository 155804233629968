export enum ModalityTypes {
    EMPTY = 0,
    ASIS = 1,
    CANC = 2,
    ASCA = 3
}

export type ProductsComparative = {
    saleId: string;
    products: ProductInfo[];
    coverages: CoverageProductsInfo[];
};

export type ProductInfo = {
    code: string;
    description?: string;
    amount?: number;
    dateStart?: Date;
    dateFinish?: Date;
};

export type CoverageProductsInfo = {
    code: string;
    description: string;
    values: CoverageProductsValue[];
};

export type CoverageProductsValue = {
    productCode: string;
    value: string;
};
