import React, {
    useState,
    useEffect,
    useCallback,
    useMemo,
    FocusEventHandler,
    useRef,
    forwardRef,
    Ref,
    useImperativeHandle,
    RefAttributes,
    PropsWithoutRef
} from 'react';
import { SelectField, SelectRef } from '@generics/select';
import { Button } from '@generics/button';
import { useTranslation } from 'react-i18next';
import { withInputLabel, InputLabelProps } from '@generics/with-input-label';
import { FormatOptionLabelMeta } from 'react-select';
import { Chip } from '@generics/chip';
import { useUpdateEffect } from '@hooks/use-update-effect';

import './select-multiple.scss';

type SelectMultipleProps<T, R> = {
    name: string;
    placeholder?: string;
    initialValues?: T[];
    options?: T[];
    getOptionLabel?: (option: T) => string;
    getOptionValue?: (option: T) => R;
    onChange?: (values?: T[]) => void;
    orderBy?: keyof T | ((value: T) => string);
    isOptionDisabled?: (option: T) => boolean;
    formatOptionLabel?: (option: T, labelMeta: FormatOptionLabelMeta<T>) => React.ReactNode;
    isLoading?: boolean;
    hasError?: boolean;
    disabled?: boolean;
    onBlur?: FocusEventHandler;
    autoFocus?: boolean;
};

export type SelectMultipleRef = {
    focus: () => void;
};

const SelectMultiple = <T, R>(
    {
        name,
        placeholder,
        initialValues,
        options,
        getOptionLabel,
        getOptionValue,
        onChange,
        orderBy,
        isOptionDisabled,
        formatOptionLabel,
        isLoading,
        hasError,
        disabled = false,
        onBlur,
        autoFocus = false
    }: SelectMultipleProps<T, R>,
    ref: Ref<SelectMultipleRef>
) => {
    const { t } = useTranslation();
    const [selectedOption, setSelectedOption] = useState<T | null>(null);
    const [values, setValues] = useState<T[] | undefined>(initialValues);
    const selectRef = useRef<SelectRef>(null);

    useImperativeHandle(ref, () => ({
        focus: () => {
            selectRef.current?.focus();
        }
    }));

    useEffect(() => {
        setValues(initialValues);
    }, [initialValues]);

    useUpdateEffect(() => {
        onChange?.(values);
    }, [values]);

    const onSelectChange = useCallback(
        (value: T) => {
            setSelectedOption(value);
            selectRef.current?.focus();
        },
        [values]
    );

    const checkOptionDisabled = useCallback(
        (value: T) => isOptionDisabled?.(value) || (values && values.indexOf(value) !== -1),
        [values, isOptionDisabled]
    );

    const onRemoveOption = useCallback(
        (option: T) => () => {
            setValues(values?.filter(v => v !== option));
            selectRef.current?.focus();
        },
        [values]
    );

    const getLabel = (option: T): T | string => getOptionLabel?.(option) || option;

    const getValue = (option: T): T | R => getOptionValue?.(option) || option;

    const isAddButtonDisabled = useMemo(() => !selectedOption, [selectedOption]);

    const onAddSelectedOption = useCallback(() => {
        if (selectedOption) {
            setValues([...(values || []), selectedOption]);
            setSelectedOption(null);
        }
    }, [selectedOption, values]);

    const onKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLElement>) => {
            if (e.keyCode === 13 && !isAddButtonDisabled) {
                e.preventDefault();
                onAddSelectedOption();
                selectRef?.current?.focus();
            }
        },
        [onAddSelectedOption, selectRef]
    );

    const optionsToShow = useMemo(
        () =>
            values && values.length > 0
                ? options?.filter(o => !values.some(v => getValue(o) === getValue(v)))
                : options,
        [options, values]
    );

    return (
        <div className="ea-select-multiple">
            <div className="ea-select-multiple__input-container">
                <SelectField
                    name={name}
                    autoFocus={autoFocus}
                    ref={(selectRef as unknown) as () => void}
                    getOptionLabel={getOptionLabel}
                    getOptionValue={getOptionValue}
                    options={optionsToShow}
                    onChange={onSelectChange}
                    isOptionDisabled={checkOptionDisabled}
                    isClearable={false}
                    disableAutoOpenMenu={true}
                    value={selectedOption}
                    backspaceRemovesValue={true}
                    onKeyDown={onKeyDown}
                    placeholder={placeholder}
                    orderBy={orderBy}
                    formatOptionLabel={formatOptionLabel}
                    isLoading={isLoading}
                    hasError={hasError}
                    isDisabled={disabled}
                    onBlur={onBlur}
                />
                <Button
                    type="button"
                    size="default"
                    value={t('generics.add')}
                    onClick={onAddSelectedOption}
                    disabled={isAddButtonDisabled}
                />
            </div>
            {values && values.length > 0 && (
                <ul className="ea-select-multiple__selected-options">
                    {values?.map((v, index) => (
                        <li
                            key={`${getOptionValue?.(v)}` || index}
                            className="selected-option__item">
                            <Chip value={getLabel(v)} onClose={onRemoveOption(v)} />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

const SelectMultipleWithRef = forwardRef(SelectMultiple) as <T, R>(
    props: PropsWithoutRef<SelectMultipleProps<T, R>> & RefAttributes<SelectMultipleRef>
) => React.ReactElement<SelectMultipleProps<T, R> & RefAttributes<SelectMultipleRef>>;

const SelectMultipleInputLabel = withInputLabel(SelectMultipleWithRef) as <T, R>(
    props: SelectMultipleProps<T, R> & InputLabelProps<T>
) => React.ReactElement<SelectMultipleProps<T, R> & InputLabelProps<T>>;

export { SelectMultipleWithRef as SelectMultiple, SelectMultipleInputLabel };
