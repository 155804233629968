import React, {
    useContext,
    createContext,
    useEffect,
    useCallback,
    useReducer,
    PropsWithChildren,
    useMemo
} from 'react';
import { useHttp, isComplete } from '@services/http';
import { getHasCustomerData, getCustomerData } from '@services/customer-service';
import { useAuthContext, useAuthContextActions } from '@context/auth/auth-context';
import { getBusinessLines } from '@services/business-lines-service';
import { UserProviderReducer, initialValues } from '@context/user/user-provider-reducer';
import { AGENT_DATA_KEY } from '@utils/constants';

export type UserData = {
    name: string;
    surname: string;
    dni: string;
};

export type AgentData = {
    entityId: string;
    agentId?: number;
    agencyName?: string;
    iconPath?: string;
    isManualAgent?: boolean;
    salesType?: string;
    validateToken?: string;
    token?: string;
    isLanding?: boolean;
};

type UserContextProps = {
    hasUserData: boolean;
    userData?: UserData;
    agentData?: AgentData;
    hasBusinessLines: boolean;
    isLoading: boolean;
};

type UserContextActionsProps = {
    setUserData: (value: UserData) => void;
    setUserHasData: (value: boolean) => void;
    refreshUserData: () => void;
    setAgentData: (agentData?: AgentData) => void;
};

const UserContext = createContext<Partial<UserContextProps>>({}) as React.Context<UserContextProps>;
const UserContextActions = createContext<Partial<UserContextActionsProps>>({}) as React.Context<
    UserContextActionsProps
>;

export type UserProviderState = {
    hasUserData: boolean;
    userData?: UserData;
    agentData?: AgentData;
    hasBusinessLines: boolean;
    isLoading: boolean;
};

const UserProvider = ({ children }: PropsWithChildren<{}>) => {
    const { user, isLoading } = useAuthContext();
    const { initialize } = useAuthContextActions();

    const [state, dispatch] = useReducer(UserProviderReducer, { ...initialValues });

    const [hasCustomerData, fetchHasCustomerData] = useHttp(getHasCustomerData);
    const [userInfo, fetchUserInfo] = useHttp(getCustomerData);
    const [businessLines, fetchBusinessLines] = useHttp(getBusinessLines, false);

    useEffect(() => {
        const loadAgentData = (): boolean => {
            const data = sessionStorage.getItem(AGENT_DATA_KEY);
            if (data) {
                const agentData = JSON.parse(data) as AgentData;
                if (agentData?.entityId) {
                    dispatch({ type: 'SET_AGENT_DATA', agentData });
                    return true;
                }
            }
            return false;
        };
        const isAgent = loadAgentData();
        initialize(!isAgent);
    }, []);

    useEffect(() => {
        if (!isLoading) {
            if (!user) {
                dispatch({ type: 'REMOVE_USER' });
            } else if (user.role === 'customer') {
                dispatch({ type: 'ON_LOADING' });
                fetchHasCustomerData();
            } else if (user.role === 'intermediary') {
                dispatch({ type: 'ON_LOADING' });
                fetchBusinessLines();
            }
        }
    }, [user, isLoading]);

    useEffect(() => {
        if (isComplete(hasCustomerData)) {
            const response = hasCustomerData.data ?? false;
            dispatch({ type: 'SET_HAS_USER_DATA', hasUserData: response });
            if (response) {
                fetchUserInfo();
            } else {
                dispatch({ type: 'LOAD_COMPLETED' });
            }
        }
    }, [hasCustomerData]);

    useEffect(() => {
        if (isComplete(businessLines)) {
            dispatch({
                type: 'SET_HAS_BUSINESS_LINES',
                hasBusinessLines: Boolean(
                    businessLines.data?.businessLines &&
                        businessLines.data.businessLines.length > 0 &&
                        businessLines.data.businessLines.some(b => b.visible)
                )
            });
        }
    }, [businessLines]);

    useEffect(() => {
        if (isComplete(userInfo)) {
            dispatch({
                type: 'SET_USER_DATA',
                userData: {
                    name: userInfo.data?.name || '',
                    surname: userInfo.data?.firstSurname || '',
                    dni: userInfo.data?.dni || ''
                }
            });
        }
    }, [userInfo]);

    const setUserHasData = useCallback((hasUserData: boolean): void => {
        dispatch({ type: 'SET_HAS_USER_DATA', hasUserData: hasUserData });
    }, []);

    const setUserData = useCallback((userData: UserData): void => {
        dispatch({
            type: 'SET_USER_DATA',
            userData
        });
    }, []);

    const refreshUserData = useCallback(() => {
        if (user?.role === 'customer') {
            fetchHasCustomerData();
        }
    }, [user?.role]);

    const setAgentData = useCallback((agentData?: AgentData) => {
        dispatch({ type: 'SET_AGENT_DATA', agentData });
        sessionStorage.setItem(AGENT_DATA_KEY, agentData ? JSON.stringify(agentData) : '');
    }, []);

    const value = useMemo(
        () => ({
            ...state
        }),
        [state]
    );

    const actionsValue = useMemo(
        () => ({
            setUserData,
            setUserHasData,
            refreshUserData,
            setAgentData
        }),
        [setUserData, setUserHasData, refreshUserData, setAgentData]
    );

    return (
        <UserContextActions.Provider value={actionsValue}>
            <UserContext.Provider value={value}>{children}</UserContext.Provider>
        </UserContextActions.Provider>
    );
};

const useUserContext = () => useContext(UserContext);
const useUserContextActions = () => useContext(UserContextActions);

export { UserContext, UserProvider, useUserContext, useUserContextActions };
