import React, { useState, ChangeEvent, useEffect } from 'react';
import { Button } from '@generics/button';
import { NumberInput } from '@generics/number-input';
import './travellers-selector.scss';

type TravellersSelectorProps = {
    value: number;
    maxValue: number;
    onChange?: (value: number | undefined) => void;
    autoFocus?: boolean;
};

const TravellersSelector = ({
    value,
    maxValue,
    onChange,
    autoFocus = false
}: TravellersSelectorProps) => {
    const [count, setCount] = useState<number | null>(value);

    useEffect(() => {
        setCount(value);
    }, [value]);

    const handleChange = (ev: ChangeEvent<HTMLInputElement>, newValue: number | null): void => {
        if (newValue && newValue > maxValue) {
            ev.preventDefault();
        } else {
            setCount(newValue);
        }
    };

    useEffect(() => {
        if (count == null) {
            setCount(0);
        } else {
            onChange?.(count);
        }
    }, [count]);

    return (
        <div className="travellers-selector__container flex middle">
            <Button
                kind="primary"
                value=""
                type="button"
                icon="less"
                size="m"
                round
                disabled={count == null || count <= 0 || count > maxValue}
                onClick={() => setCount((count ?? 0) - 1)}
            />
            <NumberInput
                value={count}
                onChange={handleChange}
                autoFocus={autoFocus}
                type="integer"
            />
            <Button
                kind="primary"
                value=""
                type="button"
                icon="plus"
                size="m"
                round
                disabled={count != null && count >= maxValue}
                onClick={() => setCount((count ?? 0) + 1)}
            />
        </div>
    );
};

export { TravellersSelector };
