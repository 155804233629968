import React, { useMemo, Ref, RefAttributes } from 'react';
import classNames from 'classnames';
import { ErrorMessage, useField } from 'formik';
import { Translate } from '../shared/translate';
import { hasMetaFieldError } from '@utils/formik-helper';
import { Icon, IconProps } from '@generics/icon';
import './with-input-label.scss';

type InputLabelComponentProps<T> = {
    hasError: boolean;
} & T;

export type InputLabelProps<T, R = {}> = {
    labelPlacement?: 'top' | 'left' | 'none';
    className?: string;
    label?: string;
    mandatoryLabel?: 'required' | 'optional';
    renderLabel?: React.ReactNode;
    name: string;
    id?: string;
    readOnly?: boolean;
    renderReadOnly?: (value: T) => string;
    icon?: IconProps;
    infoMessage?: string;
    innerRef?: Ref<R>;
};

const withInputLabel = <T, P extends object, R = {}>(
    Component: React.FunctionComponent<InputLabelComponentProps<P> & RefAttributes<R>>,
    defaultIcon?: IconProps
): React.FC<P & InputLabelProps<T, R>> => (props: InputLabelProps<T, R>) => {
    const { className: customClassName, name } = props;

    const {
        labelPlacement = 'top',
        label,
        mandatoryLabel,
        readOnly,
        renderReadOnly,
        renderLabel,
        icon,
        infoMessage,
        innerRef,
        id,
        ...restProps
    } = props;
    const [field, meta] = useField<T>(name);
    const inputLabelIcon = icon || defaultIcon;

    const getMandatoryLabel = useMemo(() => {
        switch (mandatoryLabel) {
            case 'required':
                return '*';
            case 'optional':
                return (
                    <span className="ea-label--optional">
                        (<Translate i18nKey="generics.optional" />)
                    </span>
                );
            default:
                return null;
        }
    }, [mandatoryLabel]);

    const hasError = useMemo(() => hasMetaFieldError(meta), [meta.touched, meta.error]);

    const containerClassNames = classNames(
        {
            'ea-textfield-component': true,
            'ea-input-label-component': true,
            'with-input-label-component': true,
            'is-readonly': readOnly,
            'label--top': labelPlacement === 'top',
            'has-error': hasError
        },
        customClassName
    );

    return readOnly ? (
        <dl className={containerClassNames} data-cy={name}>
            <dt className="is-readonly">
                {labelPlacement !== 'none' &&
                    (renderLabel || (label && <Translate i18nKey={label} />))}
            </dt>
            <dd className="is-readonly">
                {renderReadOnly ? renderReadOnly(field.value) : field.value}
            </dd>
        </dl>
    ) : (
        <div className={containerClassNames}>
            {labelPlacement !== 'none' && (
                <label className="ea-label" htmlFor={`input_${id || name}`}>
                    {renderLabel ??
                        (label ? (
                            <>
                                <Translate i18nKey={label} />
                                {getMandatoryLabel}
                            </>
                        ) : null)}
                </label>
            )}
            <div className="ea-input-label-component__input">
                <Component
                    {...(restProps as P)}
                    id={`input_${id || name}`}
                    hasError={hasError}
                    ref={innerRef}
                />
                {inputLabelIcon && <Icon {...inputLabelIcon} />}
            </div>
            {infoMessage && (
                <span className="ea-input-validation__message is-info">{infoMessage}</span>
            )}
            <ErrorMessage name={name}>
                {() => <span className="ea-input-validation__message">{meta.error}</span>}
            </ErrorMessage>
        </div>
    );
};

export { withInputLabel };
