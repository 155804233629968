declare global {
    interface Window {
        REACT_APP_OIDC_STS_AUTHORITY: string;
        REACT_APP_OIDC_CLIENT_ID: string;
        REACT_APP_OIDC_CLIENT_ROOT: string;
        REACT_APP_OIDC_CLIENT_SCOPE: string;
        REACT_APP_SERVER_URL: string;
        REACT_APP_SERVER_API_URL: string;
        REACT_APP_SERVER_CMS: string;
        REACT_APP_GTM_ID: string;
        REACT_APP_SOCIAL_FACEBOOK: string;
        REACT_APP_SOCIAL_TWITTER: string;
        REACT_APP_SOCIAL_YOUTUBE: string;
        REACT_APP_SOCIAL_INSTAGRAM: string;
        REACT_APP_SOCIAL_LINKEDIN: string;
        REACT_APP_PUBLIC_PORTAL_HELP_LINK: string;
        REACT_APP_PUBLIC_PORTAL_ABOUT_US_LINK: string;
        REACT_APP_PUBLIC_PORTAL_PRODUCTS_LINK: string;
        REACT_APP_PUBLIC_PORTAL_CONTACT_LINK: string;
        REACT_APP_PUBLIC_PORTAL_BLOG_LINK: string;
        REACT_APP_PUBLIC_PORTAL_PRESS_LINK: string;
        REACT_APP_PUBLIC_PORTAL_LEGAL_INFO_LINK: string;
        REACT_APP_PUBLIC_PORTAL_TERMS_LINK: string;
        REACT_APP_EXTERNAL_LINK_TRAVELNET: string;
        REACT_APP_EXTERNAL_LINK_ECLAIMS: string;
    }
}

export default {
    oidc: {
        stsAuthority: window.REACT_APP_OIDC_STS_AUTHORITY,
        clientId: window.REACT_APP_OIDC_CLIENT_ID,
        clientRoot: window.REACT_APP_OIDC_CLIENT_ROOT,
        clientScope: window.REACT_APP_OIDC_CLIENT_SCOPE
    },
    i18n: {
        languages: ['EN', 'ES'],
        defaultLanguage: 'ES',
        namespaces: [''],
        defaultNamespace: ''
    },
    server: {
        serverUrl: window.REACT_APP_SERVER_URL,
        apiUrl: window.REACT_APP_SERVER_API_URL,
        CMS: window.REACT_APP_SERVER_CMS
    },
    gtm: {
        id: window.REACT_APP_GTM_ID
    },
    debug: false,
    socialItems: [
        {
            href: `https://www.facebook.com/${window.REACT_APP_SOCIAL_FACEBOOK}`,
            iconName: 'facebook',
            iconTitle: 'Facebook'
        },
        {
            href: `https://twitter.com/${window.REACT_APP_SOCIAL_TWITTER}`,
            iconName: 'twitter',
            iconTitle: 'Twitter'
        },
        {
            href: `https://www.youtube.com/user/${window.REACT_APP_SOCIAL_YOUTUBE}`,
            iconName: 'youtube',
            iconTitle: 'YouTube'
        },
        {
            href: `https://www.instagram.com/${window.REACT_APP_SOCIAL_INSTAGRAM}`,
            iconName: 'instagram',
            iconTitle: 'Instagram'
        },
        {
            href: `https://www.linkedin.com/company/${window.REACT_APP_SOCIAL_LINKEDIN}`,
            iconName: 'linkedin',
            iconTitle: 'LinkedIn'
        }
    ],
    publicPortalItems: [
        {
            href: window.REACT_APP_PUBLIC_PORTAL_HELP_LINK,
            titleKey: 'private-header.funnel.help',
            section: 'header'
        },
        {
            href: window.REACT_APP_PUBLIC_PORTAL_ABOUT_US_LINK,
            titleKey: 'private-header.funnel.about_us',
            section: 'header'
        },
        {
            href: window.REACT_APP_PUBLIC_PORTAL_PRODUCTS_LINK,
            titleKey: 'private-header.funnel.products',
            section: 'header'
        },
        {
            href: window.REACT_APP_PUBLIC_PORTAL_CONTACT_LINK,
            titleKey: 'private-header.travelnet.contact',
            section: 'header'
        },
        {
            href: window.REACT_APP_PUBLIC_PORTAL_HELP_LINK,
            titleKey: 'policy-card.help',
            section: 'help'
        },
        {
            href: window.REACT_APP_PUBLIC_PORTAL_BLOG_LINK,
            titleKey: 'footer.blog',
            section: 'footer'
        },
        {
            href: window.REACT_APP_PUBLIC_PORTAL_CONTACT_LINK,
            titleKey: 'footer.contact',
            section: 'footer'
        },
        {
            href: window.REACT_APP_PUBLIC_PORTAL_PRESS_LINK,
            titleKey: 'footer.press',
            section: 'footer'
        },
        {
            href: window.REACT_APP_PUBLIC_PORTAL_LEGAL_INFO_LINK,
            titleKey: 'footer.legal-info',
            section: 'footer'
        },
        {
            href: window.REACT_APP_PUBLIC_PORTAL_TERMS_LINK,
            titleKey: 'footer.terms',
            section: 'footer'
        }
    ],
    externalLinks: {
        travelnet: window.REACT_APP_EXTERNAL_LINK_TRAVELNET,
        eclaims: window.REACT_APP_EXTERNAL_LINK_ECLAIMS
    }
};
