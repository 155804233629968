import { configuration } from '@configuration';

const {
    publicPortalItems,
    server: { CMS }
} = configuration;

export const formattedUrl = (relativeUrl: string, host: string = '') => {
    const isAbsolute = new RegExp('^([a-z]+://|//)', 'i');
    if (isAbsolute.test(relativeUrl)) return relativeUrl;
    return `${host}${relativeUrl}`;
};

export const getExternalUrl = (sectionKey: string) => {
    const link = publicPortalItems.find(link => link.titleKey.includes(sectionKey));
    return link ? formattedUrl(link.href, CMS) : '#';
};

export const getQueryParam = (name: string, search: string): string | null => {
    return new URLSearchParams(search).get(name);
};
