import { UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { configuration } from '@configuration';
import { routesConfig } from '@routes/routes-config';

const {
    oidc: { stsAuthority, clientId, clientRoot, clientScope }
} = configuration;

const authConfiguration: UserManagerSettings = {
    authority: stsAuthority,
    client_id: clientId,
    redirect_uri: `${clientRoot}${routesConfig.oidc.signinCallback}`,
    silent_redirect_uri: `${clientRoot}${routesConfig.oidc.silentRenew}`,
    post_logout_redirect_uri: `${clientRoot}`,
    response_type: 'code',
    scope: clientScope,
    automaticSilentRenew: true,
    userStore: new WebStorageStateStore({ store: window.localStorage })
};

export { authConfiguration };
