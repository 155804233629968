import { FieldMetaProps, FormikHelpers, FormikHandlers } from 'formik';

const hasMetaFieldError = <T>(formikMetaFieldProps: FieldMetaProps<T>): boolean => {
    return Boolean(formikMetaFieldProps.touched && Boolean(formikMetaFieldProps.error));
};

const isValidForm = async <T>(
    formikHelpers: FormikHelpers<T> & FormikHandlers,
    showErrors: boolean = false
): Promise<boolean> => {
    const result = await formikHelpers.validateForm();
    const isValid = Boolean(result && Object.keys(result).length === 0);
    if (!isValid && showErrors) {
        formikHelpers.handleSubmit();
    }
    return isValid;
};

const submitForm = async <T>(formikHelpers: FormikHelpers<T>): Promise<boolean> => {
    try {
        await formikHelpers.submitForm();
        return true;
    } catch (e) {
        return false;
    }
};

export { hasMetaFieldError, isValidForm, submitForm };
