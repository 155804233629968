import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

type TranslateProps<T> = {
    i18nKey: string;
    options?: T;
};

const Translate = <T,>({ i18nKey, options }: TranslateProps<T>) => {
    const { t } = useTranslation();
    return React.createElement(Fragment, null, t(i18nKey, options));
};

export { Translate };
