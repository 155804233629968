import React, {
    useEffect,
    useReducer,
    useMemo,
    useContext,
    useCallback,
    PropsWithChildren
} from 'react';
import { AuthServiceFactory } from '@services/auth/auth-service';
import { AuthUser, getAuthorizationHeaderValue } from '@services/auth/auth-types';
import { AuthProviderReducer } from '@context/auth/auth-provider-reducer';
import { User, UserManagerSettings } from 'oidc-client';
import { setAuthToken } from '@services/http';
import { useHistory } from 'react-router-dom';
import { LoginState } from '@modules/app/login';
import { trackException } from '@services/exceptions/track-service';
import { routesConfig } from '@routes/routes-config';

type AuthContextProps = {
    isLoading: boolean;
    user?: AuthUser;
};

type AuthContextActionsProps = {
    goLoginPage: (showRegistryArea: boolean, loginHeaderId: string) => Promise<void>;
    login: (
        showRegistryArea: boolean,
        loginHeaderId: string,
        redirectUri?: string
    ) => Promise<void>;
    goLogoutPage: () => void;
    logout: () => Promise<void>;
    completeLogin: () => Promise<void>;
    completeSilentRenewToken: () => Promise<void>;
    initialize: (loadUser: boolean) => void;
    register: (showRegistryArea: boolean, loginHeaderId: string, redirectUri?: string) => void;
    signinSilent: () => Promise<void>;
};

type AuthProviderProps = {
    authConfiguration: UserManagerSettings;
    debug: boolean;
};

const AuthContext = React.createContext<Partial<AuthContextProps>>({}) as React.Context<
    AuthContextProps
>;

const AuthContextActions = React.createContext<Partial<AuthContextActionsProps>>(
    {}
) as React.Context<AuthContextActionsProps>;

export type AuthProviderState = AuthContextProps;

const AuthProvider = ({
    children,
    authConfiguration,
    debug
}: PropsWithChildren<AuthProviderProps>) => {
    const history = useHistory();
    const authService = useMemo(() => AuthServiceFactory(authConfiguration, debug), [
        authConfiguration,
        debug
    ]);

    const [state, dispatch] = useReducer(AuthProviderReducer, {
        isLoading: true
    });

    useEffect(() => {
        if (!state.isLoading && state.user) {
            let redirect = sessionStorage.getItem('auth:redirect');
            if (redirect) {
                if (redirect === routesConfig.funnel.paymentSuccess) {
                    redirect = routesConfig.customerArea.policies;
                }
                sessionStorage.removeItem('auth:redirect');
                history.push(redirect);
            }
        }
    }, [state.user, state.isLoading]);

    const goLoginPage = useCallback(
        async (showRegistryArea: boolean, loginHeaderId: string): Promise<void> => {
            history.push(routesConfig.oidc.login, {
                showRegistryArea,
                loginHeaderId,
                previousPath: history.location.pathname
            } as LoginState);
        },
        []
    );

    const goLogoutPage = useCallback(() => {
        history.push(routesConfig.oidc.logout);
    }, []);

    const login = useCallback(
        async (
            showRegistryArea: boolean,
            loginHeaderId: string,
            redirectUri?: string
        ): Promise<void> => {
            dispatch({ type: 'ON_LOADING' });
            sessionStorage.setItem(
                'auth:redirect',
                redirectUri ? redirectUri : window.location.pathname
            );
            await authService.authenticateUser(showRegistryArea, loginHeaderId);
        },
        [authService]
    );

    const completeLogin = useCallback(async (): Promise<void> => {
        await authService.authenticateUserCallback();
    }, [authService]);

    const completeSilentRenewToken = useCallback(async (): Promise<void> => {
        await authService.signInSilentCallback();
    }, [authService]);

    const logout = useCallback(async (): Promise<void> => {
        setAuthToken('');
        await authService.signOutUser();
    }, [authService]);

    const onUserLoaded = useCallback((user: User): void => {
        setAuthToken(getAuthorizationHeaderValue(user));
        dispatch({ type: 'LOAD_USER', user });
    }, []);

    const onUserUnloaded = useCallback(async (): Promise<void> => {
        setAuthToken('');
        dispatch({ type: 'UNLOAD_USER' });
        await authService.revokeAccessToken();
    }, [authService]);

    const onError = useCallback((error: Error): void => {
        dispatch({ type: 'LOAD_COMPLETED' });
        trackException(error);
    }, []);

    const onAccessTokenExpiring = useCallback(async (): Promise<void> => {
        try {
            await authService.signInSilent();
        } catch (err) {
            trackException(err as Error);
        }
    }, [authService]);

    const onAccessTokenExpired = useCallback(async (): Promise<void> => {
        setAuthToken('');
        dispatch({ type: 'UNLOAD_USER' });
    }, [authService]);

    useEffect(() => {
        authService.registerEvents(
            onUserLoaded,
            onUserUnloaded,
            onError,
            onAccessTokenExpiring,
            onAccessTokenExpired
        );
        return () =>
            authService.unregisterEvents(
                onUserLoaded,
                onUserUnloaded,
                onError,
                onAccessTokenExpiring,
                onAccessTokenExpired
            );
    }, [
        authService,
        onUserLoaded,
        onUserUnloaded,
        onError,
        onAccessTokenExpiring,
        onAccessTokenExpired
    ]);

    const initialize = useCallback(
        async (loadUser: boolean = true): Promise<void> => {
            dispatch({ type: 'ON_LOADING' });
            if (loadUser && !state.user) {
                const userLoaded = await authService.tryLoadUser();
                if (!userLoaded) {
                    dispatch({ type: 'LOAD_COMPLETED' });
                }
            } else {
                dispatch({ type: 'LOAD_COMPLETED' });
            }
        },
        [authService, state.user]
    );

    const register = useCallback(
        async (showRegistryArea: boolean, loginHeaderId: string, redirectUri?: string) => {
            dispatch({ type: 'ON_LOADING' });
            sessionStorage.setItem(
                'auth:redirect',
                redirectUri ? redirectUri : window.location.pathname
            );
            await authService.authenticateUser(showRegistryArea, loginHeaderId, true);
        },
        [authService]
    );

    const signinSilent = useCallback(async () => {
        await authService.signInSilent();
    }, [authService]);

    const actionsValue = useMemo(
        () => ({
            goLoginPage,
            login,
            goLogoutPage,
            logout,
            completeLogin,
            completeSilentRenewToken,
            initialize,
            register,
            signinSilent
        }),
        [
            goLoginPage,
            login,
            goLogoutPage,
            logout,
            completeLogin,
            completeSilentRenewToken,
            initialize,
            register,
            signinSilent
        ]
    );

    return (
        <AuthContextActions.Provider value={actionsValue}>
            <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
        </AuthContextActions.Provider>
    );
};

const AuthConsumer: React.ExoticComponent<React.ConsumerProps<AuthContextProps>> =
    AuthContext.Consumer;

const useAuthContext = () => useContext(AuthContext);
const useAuthContextActions = () => useContext(AuthContextActions);

export { AuthProvider, AuthConsumer, useAuthContext, useAuthContextActions };
