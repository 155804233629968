import { User } from 'oidc-client';
import { getIntermediariesLegal } from '@services/intermediary-service';

export type AuthInfo = {
    user?: AuthUser;
    hasData?: boolean;
};

export type RoleType = 'customer' | 'intermediary';

export type AuthUser = {
    email?: string;
    username?: string;
    role?: RoleType;
};

const getAuthorizationHeaderValue = (user?: User): string =>
    user ? `${user.token_type} ${user.access_token}` : '';

const getUserRole = (user?: User): RoleType | undefined => {
    const role = user?.profile?.role;
    return typeof role === 'string' ? (role.toLowerCase() as RoleType) : undefined;
};

const mapUser = (user?: User): AuthUser | undefined =>
    user
        ? {
              email: user?.profile?.email,
              username: user?.profile?.name,
              role: getUserRole(user)
          }
        : undefined;

const isCustomerArea = (user?: AuthUser) => user?.role === 'customer';
const isIntermediary = (user?: AuthUser) => user?.role === 'intermediary';

let intermediariesLegalList: string[] = [];
const initializeIntermediariesLegalList = async () => {
    const result = await getIntermediariesLegal();
    intermediariesLegalList = result.data.value as string[];
};
initializeIntermediariesLegalList();

const isLegal = (user?: AuthUser): boolean => {
    if (!user?.username) {
        return false;
    }
    return intermediariesLegalList.includes(Number(user?.username).toString());
};

export {
    getAuthorizationHeaderValue,
    getUserRole,
    mapUser,
    isCustomerArea,
    isIntermediary,
    isLegal
};
