import { CallStatus, HttpState, HttpAction } from './http-types';

type IReducerSwitchType<T> = {
    [key: string]: () => HttpState<T>;
};

const HttpReducer = <T>(state: HttpState<T>, action: HttpAction<T>): HttpState<T> => {
    const reducerSwitch: IReducerSwitchType<T> = {
        REQUEST_START: () => ({ ...state, status: CallStatus.Loading }),
        REQUEST_ERROR: () => ({
            ...state,
            status: CallStatus.Error,
            error: action.error,
            data: action.payload
        }),
        REQUEST_END: () => ({
            ...state,
            status: CallStatus.Complete,
            error: action.error,
            data: action.payload
        })
    };
    return reducerSwitch[action.type]();
};

export { HttpReducer };
