import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Button } from './button';

export type ChipProps = {
    className?: string;
    value: string | ReactNode;
    onClose: () => void;
};

const Chip: React.FC<ChipProps> = ({ className, value, onClose }: ChipProps) => (
    <div className={classNames('ea-chip', className)}>
        <span className="ea-chip__value">{value}</span>
        <Button
            className="ea-chip__btn"
            value="close"
            icon="close"
            round
            size="xxs"
            type="button"
            onClick={onClose}
        />
    </div>
);

export { Chip };
