import React, { forwardRef, Ref } from 'react';
import classNames from 'classnames';
import { Icon } from '@generics/icon';

enum ButtonKind {
    primary,
    secondary,
    tertiary,
    round
}

enum ButtonSize {
    default,
    m,
    l,
    xs,
    xxs
}

const kindStyle = {
    primary: 'ea-button--primary',
    secondary: 'ea-button--secondary',
    tertiary: 'ea-button--tertiary',
    quaternary: 'ea-button--quaternary',
    quinary: 'ea-button--quinary',
    ghost: 'ea-button--ghost'
};

const sizeStyle = {
    default: 'size--m',
    l: 'size--l',
    m: 'size--m',
    s: 'size--s',
    xs: 'size--xs',
    xxs: 'size--xxs'
};

const shapeStyle = {
    round: 'ea-button--round'
};

const iconPosition = {
    left: 'icon--left'
};

const structureClass = {
    buttonClass: 'ea-button-component effect--ripple',
    value: 'ea-button__value',
    wide: 'width-100'
};

type ButtonProps<T> = {
    className?: string;
    value: string;
    kind?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'quinary' | 'ghost';
    onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
    type: 'submit' | 'reset' | 'button';
    disabled?: boolean;
    round?: boolean;
    wide?: boolean;
    icon?: string;
    iconLeft?: boolean;
    size?: 'default' | 'xxs' | 'xs' | 's' | 'm' | 'l';
} & T;

const Button = <T extends {}>(
    {
        icon,
        iconLeft,
        round,
        wide,
        className,
        kind,
        size,
        type,
        disabled,
        onClick,
        value,
        ...restProps
    }: ButtonProps<T>,
    ref: Ref<HTMLButtonElement>
) => {
    return (
        <button
            ref={ref}
            onClick={onClick}
            className={classNames(
                [structureClass.buttonClass],
                kind ? kindStyle[kind] : 'primary',
                size ? sizeStyle[size] : 'default',
                round ? [shapeStyle.round] : null,
                iconLeft ? [iconPosition.left] : null,
                wide ? [structureClass.wide] : null,
                icon,
                className
            )}
            type={type}
            disabled={disabled}
            {...restProps}>
            <span className={structureClass.value}>{value}</span>
            {icon && <Icon name={icon} title={value} size="xs" />}
        </button>
    );
};

const ButtonRef = forwardRef(Button);

export { ButtonRef as Button, ButtonKind, ButtonSize };
