import { UserProviderState, UserData, AgentData } from '@context/user/user-context';

type UserProviderAction =
    | { type: 'SET_HAS_USER_DATA'; hasUserData: boolean }
    | { type: 'SET_USER_DATA'; userData: UserData }
    | { type: 'SET_HAS_BUSINESS_LINES'; hasBusinessLines: boolean }
    | { type: 'SET_AGENT_DATA'; agentData?: AgentData }
    | { type: 'REMOVE_USER' | 'ON_LOADING' | 'LOAD_COMPLETED' };

const initialValues: UserProviderState = {
    hasUserData: false,
    hasBusinessLines: false,
    isLoading: true
};

const UserProviderReducer = (
    state: UserProviderState,
    action: UserProviderAction
): UserProviderState => {
    switch (action.type) {
        case 'SET_HAS_USER_DATA':
            return {
                ...state,
                hasUserData: action.hasUserData,
                userData: undefined,
                agentData: undefined
            };
        case 'SET_USER_DATA':
            return { ...state, userData: action.userData, agentData: undefined, isLoading: false };
        case 'SET_HAS_BUSINESS_LINES':
            return {
                ...state,
                hasBusinessLines: action.hasBusinessLines,
                agentData: undefined,
                isLoading: false
            };
        case 'REMOVE_USER':
            return { ...initialValues, agentData: state.agentData, isLoading: false };
        case 'SET_AGENT_DATA':
            return {
                ...initialValues,
                agentData: action.agentData,
                isLoading: false
            };
        case 'ON_LOADING':
            return { ...state, isLoading: true };
        case 'LOAD_COMPLETED':
            return { ...state, isLoading: false };
    }
};

export { UserProviderReducer, initialValues };
