import { useRef, useEffect, DependencyList, EffectCallback } from 'react';

const useUpdateEffect = (effect: EffectCallback, deps: DependencyList = []) => {
    const firstUpdate = useRef(true);
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        return effect();
    }, deps);
};

export { useUpdateEffect };
