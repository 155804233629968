import React, { PropsWithChildren, memo } from 'react';
import { useAppContext } from '@modules/app/app-context';
import { HeaderPrivateZone } from '@shared/private-header';
import classNames from 'classnames';
import { Footer } from '@shared/footer';
import { useUserContext } from '@context/user/user-context';

const AppLayout = memo(({ children }: PropsWithChildren<{}>) => {
    const { agentData } = useUserContext();
    const { isTravelnetLauncherInHeader, saleInProgress, productIconPath } = useAppContext();

    return (
        <>
            <HeaderPrivateZone
                saleInProgress={saleInProgress}
                showTravelnetLauncher={isTravelnetLauncherInHeader}
                secondaryIconPath={agentData?.iconPath || productIconPath}
                showUserMenu={!agentData?.entityId}
                showLinks={!agentData?.entityId}
            />
            <main
                role="main"
                className={classNames({
                    'home-main': isTravelnetLauncherInHeader
                })}>
                {children}
            </main>
            <Footer showPublicPortalItems={!agentData?.entityId} />
        </>
    );
});

export { AppLayout };
