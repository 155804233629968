type AddToDateParams = {
    years?: number;
    months?: number;
    days?: number;
};

export const getFormattedDate = (date: Date, language = 'es') =>
    date
        ? new Date(date).toLocaleDateString(language, {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
          })
        : null;

export const getFormattedDateHour = (date: Date, language = 'es') =>
    date
        ? new Date(date).toLocaleDateString(language, {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: 'numeric',
              minute: '2-digit'
          })
        : null;

export const dateToIsoDate = (date: Date): Date => {
    const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    return new Date(Date.UTC(utcDate.getFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate()));
};

export const isDate18orMoreYearsOld = (date: Date) => {
    return new Date(date.getFullYear() + 18, date.getMonth(), date.getDate()) <= new Date();
};

export const currentDate1MonthLess = () => {
    const current = new Date();
    return new Date(current.getFullYear(), current.getMonth() - 1, current.getDate());
};

export const isDate1YearLess = (date: Date) => {
    const current = new Date();
    return new Date(current.getFullYear() - 1, current.getMonth(), current.getDate()) <= date;
};

export const currentDate1YearLess = () => {
    const current = new Date();
    return new Date(current.getFullYear() - 1, current.getMonth(), current.getDate());
};

export const compareDate1YearLess = (from: Date, to: Date) => {
    if (!from || !to) return true;

    return (
        new Date(to.getFullYear() - 1, to.getMonth(), to.getDate()) <= from &&
        new Date(from.getFullYear() + 1, from.getMonth(), from.getDate()) >= to
    );
};

export const addToDate = (date: Date, { years, months, days }: AddToDateParams): Date => {
    const current = date;
    return new Date(
        current.getFullYear() + (years || 0),
        current.getMonth() + (months || 0),
        current.getDate() + (days || 0)
    );
};

export const addToCurrentDate = (params: AddToDateParams): Date => {
    return addToDate(new Date(), params);
};

export const getDaysBetweenDates = (date1: Date, date2: Date): number => {
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const secondDate = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
    return Math.round(Math.abs((+firstDate - +secondDate) / oneDay));
};

export const getAge = (date: Date): number => {
    const diff_ms = Date.now() - date.getTime();
    const age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
};
