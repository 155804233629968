import { AxiosResponse } from 'axios';
import { withHttpClient } from './http';
import { Country, Province } from '@services/models/countries-model';
import { HTTP_CACHE_MASTER_DATA } from '@utils/constants';

const getCountries = (language?: string): Promise<AxiosResponse<Country[]>> =>
    withHttpClient(httpClient =>
        httpClient.get<Country[]>(`/countries/${language}`, {
            cache: HTTP_CACHE_MASTER_DATA
        })
    );

const getProvinces = (countryId?: string): Promise<AxiosResponse<Province[]>> =>
    withHttpClient(httpClient =>
        httpClient.get<Province[]>(`/countries/${countryId}/provinces`, {
            cache: HTTP_CACHE_MASTER_DATA
        })
    );

export { getCountries, getProvinces };
