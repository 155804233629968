import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import { HttpResponseError } from '@services/exceptions';
import { httpClientCache } from '@services/http';

const request = (getAuthToken: () => string) => (
    config: AxiosRequestConfig
): AxiosRequestConfig => {
    const token = getAuthToken();
    if (token) {
        config.headers.Authorization = token;
    }
    if (config.cache) {
        config.adapter = httpClientCache.adapter;
    }
    return config;
};

const errorResponse = (error: AxiosError): AxiosResponse => {
    if (error && error.response) {
        throw new HttpResponseError(
            error.response.status,
            error.response.data,
            'Error from API',
            `Request ${error.request.responseURL} failed with status ${error.response.status}`
        );
    }
    throw error;
};

export { request, errorResponse };
