import { IAxiosCacheAdapterOptions } from 'axios-cache-adapter';

export const VALIDATIONS = {
    SPAIN: {
        id: 'ES',
        name: 'ESPAÑA'
    },
    PORTUGAL: {
        id: 'PT',
        name: 'PORTUGAL'
    }
};

export const LANGUAGE = {
    ES: 'ES',
    EN: 'EN',
    EU: 'EU'
};

export const INITIAL_PAGE = 1;
export const ITEMS_PER_PAGE = 10;
export const PAGINATION_OVERFLOW_VALUE = '...';
export const CUSTOMER_HEADER_ID = 'CUSTOMER';
export const INTERMEDIARY_HEADER_ID = 'INTERMEDIARY';

export const HTTP_CACHE_MASTER_DATA: IAxiosCacheAdapterOptions = {
    maxAge: 60 * 60 * 1000 // 1 hour
};

export const AGENT_DATA_KEY = 'agent-data';

export const BUDGET_DOCUMENT_ID = 'PRE';

import es from '../assets/images/es.svg';
import de from '../assets/images/de.svg';
import en from '../assets/images/en.svg';
import fr from '../assets/images/fr.svg';
import it from '../assets/images/it.svg';
import pt from '../assets/images/pt.svg';
import eu from '../assets/images/eu.svg';

export const getImages = (lang: string) => {
    switch (lang) {
        case 'es':
        case LANGUAGE.ES:
            return es;
        case 'de':
        case 'DE':
            return de;
        case 'en':
        case LANGUAGE.EN:
            return en;
        case 'FR':
            return fr;
        case 'IT':
            return it;
        case 'PT':
            return pt;
        case 'eu':
        case LANGUAGE.EU:
            return eu;
        default:
            return es;
    }
};
