import { AuthProviderState } from '@context/auth/auth-context';
import { mapUser } from '@services/auth/auth-types';
import { User } from 'oidc-client';

export type AuthProviderAction =
    | { type: 'ON_LOADING' | 'LOAD_COMPLETED' | 'UNLOAD_USER' }
    | { type: 'LOAD_USER'; user: User };

export const AuthProviderReducer = (
    state: AuthProviderState,
    action: AuthProviderAction
): AuthProviderState => {
    switch (action.type) {
        case 'ON_LOADING':
            return { ...state, isLoading: true };
        case 'LOAD_COMPLETED':
            return { ...state, isLoading: false };
        case 'LOAD_USER': {
            const { user } = action;
            return {
                ...state,
                user: mapUser(user),
                isLoading: false
            };
        }
        case 'UNLOAD_USER':
            return {
                ...state,
                user: undefined,
                isLoading: false
            };
    }
};
